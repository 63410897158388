import React, { useEffect } from 'react';
import './App.css';
import {
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

import FloatingWhatsApp from 'react-floating-whatsapp';
import { staticAsset } from './utils/utils';
import { useTranslation } from 'react-i18next';
import NotFound from './NotFound';
import DefaultLayout from "./layout/DefaultLayout";
import Login from "./Auth/Login";
import Register from "./Auth/register";
import InvestmentInvestor from "./views/guests/investments/investment";
import Projects from "./views/projects/index";
import ProjectDetails from "./views/projects/projects-details";
import SimulationCalculator from "./views/projects/calculate-roi";
import Lots from "./views/lots";
import LotsDetails from "./views/lots/LotsDetails";
import ProtectedRoute from './utils/ProtectedRoute';
import VerifyEmail from './utils/VerifyEmail';

function App() {
  const { pathname } = useLocation();
  const { t } = useTranslation("chatWhatsapp");


  const customTextChat = () => {
    return <>
      {t('Hello')} <br />
      {t('message')}
    </>
  }

  useEffect(() => {
    const whatsappButton = document.querySelector('.styles-module_whatsappButton__IWx9V');
    const textAdded = document.querySelector('.add-floting-text-in-whatsapp-btn');

    // Create a <a> element
    if (whatsappButton && !textAdded) {
      const elem = document.createElement("a");

      // create a class
      elem.setAttribute('class', 'add-floting-text-in-whatsapp-btn')

      // Create a new text node
      const text = document.createTextNode(t('help'));

      // Append text node to <a> node
      elem.appendChild(text);

      // Finally, append <li> to <div> node
      whatsappButton.appendChild(elem);
    }

  })




  return (
    <>
      <Routes>
        <Route path='/' element={<DefaultLayout />}>
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route path='/' element={<Projects />} />
          <Route path='/projets/:code' element={<ProjectDetails />} />
          <Route path='/simulation-calculator/:code' element={<SimulationCalculator />} />
          <Route path='/lots' element={<Lots />} />
          <Route path='/lots/:lotId' element={<LotsDetails />} />
          <Route path='/client/investment/:code' element={
            <ProtectedRoute>
              <VerifyEmail>
                <InvestmentInvestor />
              </VerifyEmail>
            </ProtectedRoute>
          } />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>

      {!pathname.includes('/projets/') &&
        <>
          <FloatingWhatsApp
            avatar={staticAsset("logo-blanc.png")}
            phoneNumber="221770952155"
            accountName="REISE"
            chatMessage={customTextChat()}
            statusMessage=""
            className='custom-z-index'
            height={327}
            placeholder={t('Type a message')}
          />
        </>
      }
    </>
  );
}

export default App;
