import React from 'react';
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import APP_ENV from '../config/Env';

const center = { lat: 14.4750607, lng: -14.4529612 };
const zoom = 6;

const Marker = (options) => {
  const [marker, setMarker] = React.useState();
  // const contentRef = React.useRef(null);

  React.useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  React.useEffect(() => {

    if (marker) {
      // const infowindow = new window.google.maps.InfoWindow({});
      marker.setOptions(options);

      // marker.addListener("click", () => {
      //   infowindow.open({
      //     anchor: marker,
      //     shouldFocus: false
      //   });
      // });
    }
  }, [marker, options]);

  return null;
};


const render = (status) => {
  if (status === Status.LOADING) return <h3>{status} ..</h3>;
  if (status === Status.FAILURE) return <h3>{status} ...</h3>;
  return null;
};

const Map = ({ onClick, onIdle, children, style, ...options }) => {
  const ref = React.useRef(null);
  const [map, setMap] = React.useState();

  React.useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);

  React.useEffect(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);

  React.useEffect(() => {
    if (map) {
      ["click", "idle"].forEach((eventName) =>
        window.google.maps.event.clearListeners(map, eventName)
      );

      if (onClick) {
        map.addListener("click", onClick);
      }

      if (onIdle) {
        map.addListener("idle", () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);

  return (
    <>
      <div ref={ref} style={style} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
};


const GoogleMaps = (props) => {
  const [map, setMap] = React.useState(null);
  const onLoad = React.useCallback((map) => setMap(map), []);
  React.useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      props.markers.map(marker => {
       return bounds.extend(marker);
      });
      map.fitBounds(bounds);
    }
  }, [map, props.markers]);

  return (
    <Wrapper apiKey={APP_ENV.google_maps_api_key} render={render}>
      <Map
        center={center}
        zoom={zoom}
        onLoad={onLoad}
        style={props.containerStyle}
      >
        {props.markers.map((marker, idx) => {
          return <Marker key={idx} position={marker} />;
        })}
      </Map>
    </Wrapper>
  )
}
export default GoogleMaps
