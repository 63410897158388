import { useState } from 'react';

import { useMutation } from "@apollo/client/react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CREATE_USER } from "../Queries";
import './account.css'
import PhoneInput from 'react-phone-input-2';
import { useTranslation } from 'react-i18next';


const CreateUserSuccess = () => {
  const { t } = useTranslation("register");
  return (
    <Row className="container d-flex justify-content-center">
      <Col lg={8}>
        <h5 className="text-uppercase fw-bold font-base text-center text-blue">
          {t('confirmation of the creation account')}
        </h5>

        <p className="text-center py-4 text-notification" dangerouslySetInnerHTML={{ __html: t('message after creation account') }}></p>


        <div className="d-flex justify-content-center py-4">
          <Link to="/login" className="notifation btn-return">
            {t('RETURN TO LOGIN PAGE')}
          </Link>
        </div>
      </Col>
    </Row>
  )
}

function Register() {
  const { t } = useTranslation("register");
  const [validated, setValidated] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(true);
  const [doShowError, setDoShowError] = useState(false);
  const [formState, setFormState] = useState({
    nom: '',
    prenom: '',
    telephone: '',
    email: '',
    password: '',
    confirmPassword: '',
    type: 'Investisseur',
    langue: 'Français',
    etre_informe: false,
    condition: false,
    indTel: ''
  });


  const [addUser, { data, loading }] = useMutation(CREATE_USER, {
    variables: {
      nom: formState.nom,
      prenom: formState.prenom,
      telephone: formState.telephone,
      email: formState.email,
      password: formState.password,
      type: formState.type,
      langue: formState.langue,
      etre_informe: formState.etre_informe,
      condition: formState.condition,
      indTel: formState.indTel,
    },
    onCompleted: (data) => {},
    onError: ({ graphQLErrors, networkError, response }) => {
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, extensions }) => {
          if (extensions?.validation?.email[0]) setDoShowError(true)
        }

        );
      }

    }
  })


  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setFormState({
      ...formState,
      [name]: value
    })
  }

  const handleConfimPassword = () => {
    let isValid = true;
    if (formState.password !== formState.confirmPassword) {
      isValid = false;
      setConfirmPassword(false)
    } else {
      setConfirmPassword(true)
    }

    return isValid;
  }

  const userSubmitHandler = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else if (handleConfimPassword()) {
      addUser();
    }

    setValidated(true);
  };



  return (
    <>

      {data === undefined ?
        <Row className="container d-flex justify-content-center">
          <h5 className="text-uppercase fw-bold font-base text-center text-blue">
            {t('JOIN REISE')}
          </h5>
          <p className="text-center py-1">
            {t('Already have an account?')} <Link to="/login"> {t('sign in here')} </Link>
          </p>
          <Col lg={8}>
            <Form
              className="form-register"
              autoComplete="off"
              noValidate validated={validated}
              onSubmit={userSubmitHandler}
            >
              <Form.Group className="mb-3" controlId="formFirstName">
                <Form.Label className="text-uppercase text-gray font-base fw-500">
                  {t('FIRST NAME')}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="prenom"
                  value={formState.prenom}
                  onChange={handleChange}
                  placeholder={t('first name')}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {t('field required')}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formLastName">
                <Form.Label className="text-uppercase text-gray font-base fw-500">
                  {t('LAST NAME')}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="nom"
                  value={formState.nom}
                  onChange={handleChange}
                  placeholder={t('last name')}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {t('field required')}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formLastPhone">
                <Form.Label className="text-uppercase text-gray font-base fw-500">
                  {t('PHONE')}
                </Form.Label>
                <PhoneInput
                  inputProps={{
                    name: 'telephone',
                    required: true,
                    autoFocus: true
                  }}
                  inputClass='ml-5 w-94'
                  country={'us'}
                  enableSearch={true}
                  value={formState?.indTel + ' ' + formState?.telephone}
                  onChange={(phone, country) => {
                    setFormState({
                      ...formState,
                      ['telephone']: phone.replace(country.dialCode, ''),
                      ['indTel']: '+' + country.dialCode,
                    })
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="text-uppercase text-gray font-base fw-500">
                  {t('EMAIL')}
                </Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formState.email}
                  onChange={handleChange}
                  placeholder={t('email')}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {t('field required')}
                </Form.Control.Feedback>
                {doShowError && (
                  <div className="valid-feedback text-danger">
                    {t('This email has already been taken')}
                  </div>
                )}
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label className="text-uppercase text-gray font-base fw-500">
                  {t('PASSWORD')}
                </Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  value={formState.password}
                  onChange={handleChange}
                  placeholder={t('password')}
                  autoComplete="new-password"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {t('field required')}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-4" controlId="formConfirmPassword">
                <Form.Label className="text-uppercase text-gray font-base fw-500">
                  {t('CONFIRM PASSWORD')}
                </Form.Label>
                <Form.Control
                  type="password"
                  name="confirmPassword"
                  value={formState.confirmPassword}
                  onChange={handleChange}
                  placeholder={t('confirm password')}
                  autoComplete="new-password"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {t('field required')}
                </Form.Control.Feedback>
                {!confirmPassword && (
                  <div className="valid-feedback text-danger">
                    {t('The password and confirm must match')}
                  </div>
                )}
              </Form.Group>
              <Form.Group className="mb-3" controlId="formInfosInvestCheckbox">
                <Form.Check
                  type="checkbox"
                  name="etre_informe"
                  checked={formState.etre_informe}
                  onChange={handleChange}
                  label={t('informed of investment opportunities')}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formAcceptCondionCheckbox">
                <Form.Check
                  type="checkbox"
                  name="condition"
                  checked={formState.condition}
                  onChange={handleChange}
                  label={t('agree terms and conditions')}
                />
              </Form.Group>
              <div className="d-flex justify-content-center py-4">
                {!loading ?
                  <Button variant="primary" type="submit" className="btn-base-primary">
                    {t('CREATE MY ACCOUNT')}
                  </Button>
                  :
                  <Button variant="primary" disabled className="btn-base-primary">
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="px"> {t('loding')}  </span>
                  </Button>
                }
              </div>
            </Form>
          </Col>
        </Row>
        :
        <CreateUserSuccess />
      }

    </>
  );
}

export default Register;
