import { useState } from 'react';
import './filter.css';
import { useQuery } from '@apollo/client';
import { GET_LIEUX, GET_PARAMS } from '../../Queries';
import { Row, Col, ToggleButton } from 'react-bootstrap';

const LotsFilter = (props) => {
  const { lots, checkedMaxMin } = props;

  const maxLot = Math.max(...lots?.map((lot) => lot.montant));
  const minLot = Math.min(...lots?.map((lot) => lot.montant));

  const [checked, setChecked] = useState(false);
  const [checkedMin, setCheckedMin] = useState(false);
  const [checkedMax, setCheckedMax] = useState(false);
  const [localite, setLocalite] = useState([]);
  const [status, setStatus] = useState([]);
  const [superficie] = useState(new Set());

  const superficieTerrains = [];

  useQuery(GET_LIEUX, {
    variables: {},
    onCompleted: (data) => setLocalite(data?.lieux),
  });

  useQuery(GET_PARAMS, {
    variables: { type: 'Statut' },
    onCompleted: (data) => {
      setStatus(data?.parametres);
    },
  });

  for (const lot of lots) {
    lot.terrains?.map((terrain) => superficie.add(terrain.superficie));
  }

  superficie?.forEach((terrain) => superficieTerrains.push(terrain));

  return (
    <>
      <Row gap={3}>
        <h6 className="investment-status lots mt-n3  px-md-3">LOCALITE</h6>
        <Col>
          <ToggleButton
            className="mb-2 custom-checkbox lots me-4"
            type="checkbox"
            variant="outline-secondary"
            checked={checked}
            value="1"
            onChange={(e) => setChecked(e.currentTarget.checked)}
          >
            Dakar(17)
          </ToggleButton>
          <ToggleButton
            className="mb-2 custom-checkbox lots me-4"
            type="checkbox"
            variant="outline-secondary"
            checked={checked}
            value="1"
            onChange={(e) => setChecked(e.currentTarget.checked)}
          >
            Malicounda Serere(20)
          </ToggleButton>
          <ToggleButton
            className="mb-2 custom-checkbox lots me-4"
            type="checkbox"
            variant="outline-secondary"
            checked={checked}
            value="1"
            onChange={(e) => setChecked(e.currentTarget.checked)}
          >
            Malicounda Serere(20)
          </ToggleButton>
          <ToggleButton
            className="mb-2 custom-checkbox lots me-4"
            type="checkbox"
            variant="outline-secondary"
            checked={checked}
            value="1"
            onChange={(e) => setChecked(e.currentTarget.checked)}
          >
            Bambilor(200)
          </ToggleButton>
          <ToggleButton
            className="mb-2 custom-checkbox lots me-4"
            type="checkbox"
            variant="outline-secondary"
            checked={checked}
            value="1"
            onChange={(e) => setChecked(e.currentTarget.checked)}
          >
            Warang(16)
          </ToggleButton>
        </Col>
      </Row>
      <Row gap={3} className="my-4">
        <h6 className="investment-status lots">SUPERFICIE DU TERRAIN</h6>
        <Col>
          {superficieTerrains.map((sup, i) => (
            <ToggleButton
              key={i}
              className="mb-2 me-4 rounded-0 w-25"
              type="checkbox"
              variant="outline-secondary"
              checked={checked}
              value="1"
              onChange={(e) => setChecked(e.currentTarget.checked)}
            >
              {sup} m <sup>2</sup>
            </ToggleButton>
          ))}
        </Col>
      </Row>
      <Row gap={3}>
        <h6 className="investment-status lots">PRIX (MIN- MAX)</h6>
        <Col>
          <CustomedToggleButton
            label="Min"
            id="min"
            name="min"
            type="checkbox"
            checked={checkedMaxMin.get('min')}
            value={minLot}
            onChange={props.onChangeMinMax}
          />
          <CustomedToggleButton
            label="Max"
            id="max"
            name="max"
            type="checkbox"
            checked={checkedMaxMin.get('max')}
            value={maxLot}
            onChange={props.onChangeMinMax}
          />
        </Col>
      </Row>
      <Row gap={3} className="my-5">
        <h6 className="investment-status lots">MODE DE PAIEMENT</h6>

        <Col>
          <ToggleButton
            className="mb-2 me-5 rounded-0"
            type="checkbox"
            variant="outline-secondary"
            checked={checked}
            value="1"
            onChange={(e) => setChecked(e.currentTarget.checked)}
          >
            Payable en tranches seulement
          </ToggleButton>
        </Col>
      </Row>
    </>
  );
};

export default LotsFilter;

const CustomedToggleButton = (props) => {
  const [isChecked, setIsCheked] = useState(false);

  return (
    <>
      <label
        className={`${
          isChecked ? 'btn btn-primary' : 'btn btn-outline-secondary'
        } mb-2 me-5 rounded-0 px-4 w-25 text-center`}
        htmlFor={props.id}
        onClick={(e) => setIsCheked(!isChecked)}
      >
        {props.label}
      </label>
      <input
        className="hidden"
        id={props.id}
        name={props.name}
        type="checkbox"
        defaultChecked={props.checked}
        defaultValue={props.value}
        onChange={props.onChange}
      />
    </>
  );
};
