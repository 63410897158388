import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { Form, Button } from 'react-bootstrap'
import { GET_PAYS, UPDATE_USER } from '../../Queries'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useTranslation } from 'react-i18next'

const PersonalInfos = (props) => {

  const { t } = useTranslation('invest')
  const { formState, handleChange, show = false } = props;
  const [pays, setPays] = useState([]);
  const [hide, setHide] = useState('d-none');
  const [phone, setPhone] = useState(formState?.telephone);
  const [code, setCode] = useState(formState?.indTel);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(formState || {});

  const { loading, error } = useQuery(GET_PAYS, {
    variables: { langueId: parseInt(localStorage.getItem('langueId')) },
    onCompleted: data => setPays(data.paysPerLangue)
  })


  const [updateUser] = useMutation(UPDATE_USER);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setHide('')
    } else {
      updateUser({
        variables: {
          id: formState?.id,
          nom: formData?.nom,
          prenom: formData?.prenom,
          telephone: phone,
          adresse: formData?.adresse,
          pays_id: parseInt(formData?.pays_id),
          ville: formData?.ville,
          indTel: code,
          langue: formData?.langue || "Français",
        },
        onCompleted: (data) => { }
      });
      setHide('d-none')

      props.nextStep()

    }

    setValidated(true);
  };

  const handleChangeForm = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;

    setFormData({
      ...formData,
      [name]: value
    })

    handleChange(e)
  }

  if (loading) return t("loading");
  if (error) return `Error! ${error.message}`;

  return (
    <>
      <h5 className="text-uppercase font-heading text-md-center text-blue py-3 mt-4">
        {t('personal informations')}
      </h5>
      <span className={`badge badge-danger text-center custom-badge  ${hide}`} >
        {t('Please complete all required fields')}
      </span>

      <Form noValidate validated={validated} onSubmit={handleSubmit}
        className="form-register"
      >
        <Form.Group className="mb-3" controlId="formFirstName">
          <Form.Label className="text-uppercase text-gray font-base fw-500">
            {t('FIRST NAME')}
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t('first name')}
            defaultValue={formState?.prenom}
            name='prenom'
            required
            onChange={handleChangeForm}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formLastName">
          <Form.Label className="text-uppercase text-gray font-base fw-500">
            {t('LAST NAME')}
          </Form.Label>
          <Form.Control
            type="text"
            defaultValue={formState?.nom}
            name='nom'
            required
            onChange={handleChangeForm}
            placeholder={t('last name')}
          />

        </Form.Group>
        <Form.Group className="mb-3" controlId="formLastPhone">
          <Form.Label className="text-uppercase text-gray font-base fw-500">
            {t('PHONE')}
          </Form.Label>
          <PhoneInput
            inputProps={{
              name: 'telephone',
              required: true,
              autoFocus: true
            }}
            inputClass='ml-5 w-94'
            country={'us'}
            enableSearch={true}
            value={formState?.indTel + ' ' + formState?.telephone}
            onChange={(phone, country, event) => {
              setPhone(phone.replace(country.dialCode, ''))
              setCode('+' + country.dialCode)
              handleChange(event, { indTel: '+' + country.dialCode, value: phone.replace(country.dialCode, '') })
            }}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label className="text-uppercase text-gray font-base fw-500">
            {t('ADDRESS')}
          </Form.Label>
          <Form.Control
            type="text"
            defaultValue={formState?.adresse}
            name='adresse'
            required
            onChange={handleChangeForm}
            placeholder={t('Address')}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label className="text-uppercase text-gray font-base fw-500">
            {t('CITY')}
          </Form.Label>
          <Form.Control
            type="text"
            defaultValue={formState?.ville}
            name='ville'
            required
            onChange={handleChangeForm}
            placeholder={t('City')}
          />
        </Form.Group>
        <Form.Group className="mb-5">
          <Form.Label className="text-uppercase text-gray font-base fw-500">
            {t('Country')}
          </Form.Label>
          <Form.Select className='custom-select-reservation' name='pays_id'
            onChange={handleChangeForm}
            defaultValue={formState?.pays_id}
          >
            {pays.map(item => (
              <option value={item.id} key={item.id}> {item.details?.nom} </option>
            ))}
          </Form.Select>
        </Form.Group>
        {show && (
          <Form.Group className="mb-3" controlId="formCooperative">
            <Form.Label className="text-uppercase text-gray font-base fw-500">
              {t('IF YOU REPRESENT A COOPERATIVE, NAME OF THE COOPERATIVE')}
            </Form.Label>
            <Form.Control type="text" name="nom" placeholder="" required />
          </Form.Group>
        )}
        <div className="d-flex justify-content-end py-4">
          <Button
            variant="primary"
            className="btn-base-primary"
            type='submit'
          >
            {t('NEXT')}
          </Button>
        </div>
      </Form>
    </>
  );
}

export default PersonalInfos
