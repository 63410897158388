import React from "react";

import Tabs from "../../components/Tabs";

const Projects = () => {
 
        return (
         <section className="bg-base">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                            <Tabs />
                        </div>
                    </div>
                </div>
            </div>
        </section>
     )
}

export default Projects;