import React, { useState, useEffect, useRef } from 'react'
import { useQuery } from '@apollo/client/react';
import { Col, Container, Row } from 'react-bootstrap';
import ConfirmationSelected from '../../../components/investments/ConfirmationSelected';
import PersonalInfos from '../../../components/investments/PersonalInfos';
import {  GET_USER } from '../../../Queries';

import './index.css'
import { useTranslation } from 'react-i18next';
import InvestmentOfferDetails from '../../../components/investments/InvestmentOfferDetails';
import { useLocation } from 'react-router-dom';
import Loading from '../../../components/loading';



const InvestmentInvestor = () => {
  const { state } = useLocation();

  const { t } = useTranslation('invest')
  
  
  const getAuth = () => {
    const authString = localStorage.getItem('authUser');
    const userAuth = JSON.parse(authString);
    return userAuth
  };
  
  
  
  const [step, setStep] = useState(1);
  
  const [userId] = useState(getAuth()?.user?.id);
  const [auth, setAuth] = useState({});
  const ref = useRef(null)
  const [formState, setFormState] = useState();
  const stepTitle = [
    t('personal informations'),
    t('Investment amount'),
    t('Confirmation of your selection')
  ]

  const { loading, error } = useQuery(GET_USER, {
    variables: { id: userId },
    onCompleted: data => {
      setAuth(data?.user)
      setFormState({
        id: data?.user?.id,
        nom: data?.user?.nom,
        prenom: data?.user?.prenom,
        telephone: data?.user?.telephone,
        complete_telephone: data?.user?.indTel + ' ' + data?.user?.telephone,
        adresse: data?.user?.adresse,
        localite: '',
        pays: '',
        pays_id: data?.user?.pays_id,
        ville: data?.user?.ville,
        full_name: data?.user?.full_name,
        custom_telephone: data?.user?.custom_telephone,
        indTel: data?.user?.indTel,
        langue: data.user?.langue
      })
    },
    fetchPolicy: "no-cache"
  })

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
  })

  const nextStep = () => { setStep(step + 1) }
  const back = () => { setStep(step - 1) }

  const handleChange = (event, telephone = null) => {
    if (telephone) {
      setFormState({
        ...formState,
        ['indTel']: telephone.indTel,
        ['telephone']: telephone.value
      })

      return 1;
    }
    const target = event.target;
    const value = target.value;
    const name = target.name;

    // const name = target.name;
    setFormState({
      ...formState,
      [name]: value
    })
  }


  const styles = {
    background: "#0F75CE",
    borderColor: "#0F75CE",
    color: "#FFF",
  }



  if (loading) return <Loading />;
  if (error) return `Error! ${error.message}`;

  return (
    <Container ref={ref}>
      <Row className="d-flex justify-content-center py-5">
        <Col lg={10}>
          <Row className="g-4">
            {stepTitle.map((title, idx) => (
              <Col lg={4} className="px-lg-0 font-base text-gray" key={idx}>
                <span className="step-title mr-1"
                  style={idx + 1 === step ? styles : {}}>
                  {idx + 1}
                </span>
                {title}
              </Col>
            ))}

          </Row>

          {(() => {


            switch (step) {

              case 1:

                return (
                  <PersonalInfos
                    nextStep={nextStep}
                    handleChange={handleChange}
                    formState={formState}
                  />
                );

              case 2:

                return (
                  <InvestmentOfferDetails
                    nextStep={nextStep}
                    back={back}
                    project={state?.project}
                    user={auth}
                  />
                );

              case 3:

                return <ConfirmationSelected />;

              default:
                return 'Vous n\'avez pas les acces pour ce contenu';
            }

          })()}

        </Col>
      </Row>

    </Container>
  );
}

export default InvestmentInvestor;