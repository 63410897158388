import React, { useEffect, useState, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";

import { useQuery } from "@apollo/client";
import {
    GET_LIEUX,
    GET_TYPES,
    GET_PROJECTS_PER_LANGUE,
    GET_DETAILS_PROJECTS_PER_LANGUE,
    GET_PARAMS_PER_LANGUE,
} from "../../Queries";

import { Nav, Tab } from "react-bootstrap";
import CardImg from "../../components/cards/CardImg";
import ProjectFilter from "../../components/filters/ProjectFilter";
import Faq from "../../components/projectDetails/Faq";
import Investment from "../../components/projectDetails/Investment";
// import Progress from "../../components/projectDetails/Progress";
import SimilarProjects from "../../components/SimilarProjects";
import ModalAlert from "../../components/ModalAlert";
import "./index.css";
import { useTranslation } from "react-i18next";
import Fab from "../../components/fab/Fab";
import Loading from "../../components/loading";
import TheProperty from "../../components/projectDetails/TheProperty";


function ProjectDetails() {
    const { t } = useTranslation("project");
    const getAuth = () => {
        const authString = localStorage.getItem('authUser');
        const userAuth = JSON.parse(authString);
        return userAuth
    };
    const user = getAuth()?.user;
    const { code } = useParams();
    const myRef = useRef(null)
    const investmentPackRef = useRef(null)
    const [key, setKey] = useState('project');
    const [detailsProjet, setProjetDetails] = useState({});
    const [projects, setProjects] = useState([]);
    const [similarProjects, setSimilarProjects] = useState([]);
    const [localites, setlocalites] = useState([]);
    const [statutes, setStatutes] = useState([]);
    const [types, setTypes] = useState([]);
    const [minInvestMin, setMinInvestMin] = useState(0);
    const [maxInvestMin, setMaxInvestMin] = useState(1000);
    const investMinStep = 1000000;
    const roiStep = 5;
    const [minRoi] = useState(0);
    const [maxRoi, setMaxRoi] = useState(100);
    const [investmentPack] = useState(new Set(JSON.parse(localStorage.getItem('cart'))));


    const [arrayFilter, setArrayFilter] = useState({
        libelle_type: [],
        code_statut: [],
        lieu_localite: [],
        invest_min: [],
        roi: []
    });

    const [similiarFilter, setSimiliarFilter] = useState({
        code_type: '',
        code_statut: '',
        lieu_localite: '',
    });

    const filterSimilarProject = useCallback((filtered = similiarFilter) => {

        let resultFiltered = projects.filter(projet => {
            return Object.keys(filtered).every(key => projet[key] === filtered[key]);
        });

        let final_results = resultFiltered.filter((filt) => filt.code !== code)
        setSimilarProjects(final_results)
    }, [code, projects, similiarFilter])

    const { loading, error } = useQuery(GET_DETAILS_PROJECTS_PER_LANGUE, {
        variables: { code: code, langueId: parseInt(localStorage.getItem('langueId')) },
        onCompleted: (data) => {
            setProjetDetails(data.projetPerLang);
            let filter = {
                code_type: data.projetPerLang.code_type,
                code_statut: data.projetPerLang.code_statut,
                lieu_localite: data.projetPerLang.lieu_localite,
            };
            setSimiliarFilter(filter);
            filterSimilarProject(filter);
        },
        fetchPolicy: "no-cache",
    })



     useQuery(GET_PROJECTS_PER_LANGUE, {
        variables: { langueId: parseInt(localStorage.getItem('langueId')) },
        onCompleted: (data) => {
            setProjects(data.projetsPerLang);
            setMaxInvestMin(
                getNextMultiple(
                    Math.max(...data?.projetsPerLang.map((o) => o.invest_min)),
                    investMinStep
                ) ?? 1000000
            );
            setMinInvestMin(
                getPreviousMultiple(
                    Math.min(...data?.projetsPerLang.map((o) => o.invest_min)),
                    investMinStep
                ) ?? 0
            );

            setMaxRoi(
                getNextMultiple(
                    Math.max(...data?.projetsPerLang.map((o) => o.roi)),
                    roiStep
                ) ?? 100
            );
            // setMinRoi(getPreviousMultiple(Math.min(...data?.projets.map(o => o.roi)), roiStep) ?? 0)
        },
        // fetchPolicy: "no-cache",
    });

    useQuery(GET_PARAMS_PER_LANGUE, {
        variables: {
            type: "Statut",
            langueId: parseInt(localStorage.getItem('langueId'))
        },
        onCompleted: data => setStatutes(data.parametresPerLang),
        fetchPolicy: "no-cache",
    });

    useQuery(GET_TYPES, {
        variables: { langueId: parseInt(localStorage.getItem('langueId'))},
        onCompleted: (data) => {
            setTypes(data.types);
        },
        fetchPolicy: "no-cache",
    });


    useQuery(GET_LIEUX, {
        onCompleted: data => setlocalites(data.lieux)
    });

    // useEffect(() => {
    //     // handleClickFilter()
    // }, [data])


    const filterProjects = useCallback(
        () => {

            let final_result = []
            let result = projects
            let haveFilterChecked = false

            Object.keys(arrayFilter).map(key => {
                if (arrayFilter[key].length > 0) {
                    haveFilterChecked = true
                    result = result.filter(projet => arrayFilter[key].includes(projet[key]))
                } else if (key === 'roi') {

                    if (arrayFilter[key].min !== minRoi || arrayFilter[key].max !== maxRoi) {
                        haveFilterChecked = true
                        result = result.filter(projet => projet[key] >= arrayFilter[key].min && projet[key] <= arrayFilter[key].max)

                    }
                } else if (key === 'invest_min') {

                    if (arrayFilter[key].min !== minInvestMin || arrayFilter[key].max !== maxInvestMin) {
                        haveFilterChecked = true

                        result = result.filter(projet => projet[key] >= arrayFilter[key].min && projet[key] <= arrayFilter[key].max)

                    }
                }
                final_result = result
                return 1;
            });
            haveFilterChecked ? setSimilarProjects(final_result) : filterSimilarProject()
            return final_result
        },
        [arrayFilter, projects, filterSimilarProject],
    )

    useEffect(() => {

        filterProjects()
    }, [filterSimilarProject, arrayFilter, projects])

    const handleChangeStatus = (newValue, actionMeta) => {
        let statutes = []
        for (const iterator of newValue) {
            statutes.push(iterator.value)
        }
        setArrayFilter({
            ...arrayFilter,
            code_statut: statutes
        }
        )

    };

    const handleChangeTypes = (newValue, actionMeta) => {
        let types = []
        for (const iterator of newValue) {
            types.push(iterator.value)
        }
        setArrayFilter({
            ...arrayFilter,
            libelle_type: types
        }
        )

    };

    const handleChangeLocations = (newValue, actionMeta) => {
        let localites = []
        for (const iterator of newValue) {
            localites.push(iterator.value)
        }

        setArrayFilter({
            ...arrayFilter,
            lieu_localite: localites
        }
        )
    };

    const handleRange = (key, min, max) => {

        if (!((key === 'roi' && (min === minRoi && max === maxRoi))
            ||
            (key === 'invest_min' && (min === minInvestMin && max === maxInvestMin))
        )) {

            let result = similarProjects.filter(projet => projet[key] >= min && projet[key] <= max)

            if (key === 'roi') {
                setArrayFilter({
                    ...arrayFilter,
                    roi: {
                        'min': min,
                        'max': max
                    }
                })
            } else if (key === 'invest_min') {
                setArrayFilter({
                    ...arrayFilter,
                    invest_min: {
                        'min': min,
                        'max': max
                    }
                })
            }
            return result;
        } else {
            setArrayFilter({
                ...arrayFilter,
                invest_min: {
                    'min': minInvestMin,
                    'max': maxInvestMin
                },
                roi: {
                    'min': minRoi,
                    'max': maxRoi
                }
            })
        }
    }

    const handleClickFilter = () => {
        myRef.current?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
    }

    function getNextMultiple(x, step) {
        if (x) {
            return Math.ceil(x / step) * step;
        }

        return null;
    }

    function getPreviousMultiple(x, step) {
        if (x) {
            return x - Math.ceil(x % step);
        }

        return null;
    }

    const handleSelect = () => {
        setKey("investment-packages")
        investmentPackRef.current?.scrollIntoView({ behavior: "smooth", block: "start", inline: "end" });
    }


    if (loading) return <Loading />;
    if (error) return `Error! ${error.message}`;

    return (
        <div className="container">
            <ModalAlert />
            <div className="row mb-4">
                <div className="col-lg-12">
                    <CardImg projet={detailsProjet} />
                </div>
            </div>
            <div></div>
            <div className="row">
                <div className="col-lg-8">
                    <Tab.Container
                        code="my-tabs-project"
                        activeKey={key}
                        onSelect={(e) => setKey(e)}
                    >
                        <Nav
                            variant="pills"
                            className="d-flex mt-lg-n2 overflow-auto project-details"
                        >
                            <Nav.Item as="li" className="flex-fill p-md-0">
                                <Nav.Link eventKey="project"> {t('The property')} </Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li" className="flex-fill p-md-0 pl-4">
                                <Nav.Link eventKey="investment"> {t('The investment')} </Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li" className="flex-fill p-md-0 pl-4">
                                <Nav.Link eventKey="FAQ"> {t('FAQ')} </Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content className="mt-5">
                            <Tab.Pane eventKey="project">
                                <TheProperty project={detailsProjet} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="investment">
                                <Investment projet={detailsProjet} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="FAQ">
                                <Faq faqs={detailsProjet?.faqs} />
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
                <div className="col-lg-4">
                    <ProjectFilter
                        statutes={statutes}
                        types={types}
                        statusProject={detailsProjet?.code_statut}
                        lieux={localites}
                        handleChangeStatus={handleChangeStatus}
                        handleChangeTypes={handleChangeTypes}
                        handleChangeLocations={handleChangeLocations}
                        handleRange={handleRange}
                        handleClick={handleClickFilter}
                        handleSelect={handleSelect}
                        minInvestMin={minInvestMin}
                        maxInvestMin={maxInvestMin}
                        investMinStep={investMinStep}
                        invest_min={detailsProjet?.invest_min}
                        projectCout={detailsProjet?.cout}
                        minRoi={minRoi}
                        maxRoi={maxRoi}
                        roiStep={roiStep}
                        investmentPack={investmentPack}
                        projetId={detailsProjet?.id}
                        user={user}
                        similarProjects={similarProjects}
                        projects={projects}
                        project={detailsProjet}
                        arrayFilter={arrayFilter}
                        key={detailsProjet?.id}
                        // showModalInterested={showModalInterested}
                    />

                </div>
            </div>
            <div className="row" ref={myRef}>
                <SimilarProjects
                    key={similarProjects}
                    similarProjects={similarProjects}
                />
            </div>
            <Fab
                projet={detailsProjet}
                user={user}
            />
        </div>
    );
}

export default ProjectDetails;
