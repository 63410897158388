import React from 'react'
import { Alert } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const EmptyMessage = ({message}) => {
  const { t } =  useTranslation('project')
  return (
    <Alert key={'light'} variant={'light'} className='text-center'>
        { message || t('Information not available')}
    </Alert>
  )
}

export default EmptyMessage