import React from 'react'
import { Button, Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';

const ButtonNotintrested = (props) => {
  const { t } = useTranslation("project");
  return (
    <>
    {!props.loading ? 
        <Button
          variant="danger"
          className="custom-btn btn-block"
          onClick={() => props.handleRemoveInteresse()}
      >
          {t('I am no longer interested in this project')}
        </Button>
      :
      <Button variant="warning" disabled  className="btn-base-warning btn-block">
        <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
        />
            <span className="px">{t('loding')} </span>
        </Button>
      }
    </>
  )
}

export default ButtonNotintrested