import React, { useState, useEffect, useCallback, useRef } from "react";
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import { Modal, Button, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import './modaleInvest.css'
import { CurrencyConverter } from "../../utils/utils";

export default function ModalInvest(props) {

    const { loading, setShow, show, min, max, sautInvestissement } = props;
    const { t } = useTranslation('project')
    const [currentValue, setValue] = useState(min)
    const [step] = useState(sautInvestissement || 1000000)

    const range = useRef(null);

    // Convert to percentage
    const getPercent = useCallback(
        (value) => Math.round(((value - min) / (max - min)) * 100),
        [min, max]
    );


    // Set width of the range to decrease from the left side
    useEffect(() => {
        const minPercent = getPercent(currentValue);
        if (range.current) {
            range.current.style.left = `${minPercent > 27 ? minPercent - 34 : minPercent}%`;
        }
    }, [currentValue, getPercent]);

    const handleConfirm = (value) => props.handleAddInteresse && props.handleAddInteresse(value)


    return (
        <>
            <Modal
                size="lg"
                show={show}
                onHide={setShow}
                centered
                className="custom-modal-alert"
            >
                <Modal.Body className="p-4">
                    <h5 className="text-center font-base {text}-black fs-4 fw-bold mt-3">
                        {t("How much do you intend to invest")}
                    </h5>
                    <div className="py-5 custom-ranger-slider">
                        <p className="current-value-checked" ref={range}> {CurrencyConverter(currentValue)} </p>
                        <RangeSlider
                            min={min}
                            max={max}
                            className="single-thumb"
                            thumbsDisabled={[true, false]}
                            rangeSlideDisabled={true}
                            step={step}
                            onInput={values => setValue(values[1])}
                        />
                        <div className="slider__left-value px-3"> {CurrencyConverter(min)} </div>
                        <div className="slider__right-value px-4"> {CurrencyConverter(max)} </div>
                    </div>

                    <div className="d-flex justify-content-center mt-5">

                        {!loading ? (
                            <Button
                                className="custom-btn-invest btn-base-primary mb-4"
                                variant="primary"
                                onClick={() => handleConfirm(currentValue)}
                            > {t("CONFIRME")}
                            </Button>
                        ) : (
                            <Button
                                variant="primary"
                                disabled
                                className="custom-btn-invest btn-base-primary mb-4"
                            >
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span className="px"> {t("loding")} </span>
                            </Button>
                        )}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
