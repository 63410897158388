import { FaMapMarkerAlt } from "react-icons/fa";
import ProgressBar from "react-bootstrap/ProgressBar";

import { ListGroup } from "react-bootstrap";
import { BsHeartFill } from "react-icons/bs";
import { CurrencyConverter, asset } from "../../utils/utils";
import "./cardImg.css";
import { VOTING, VERIFICATIONINPROGRESS } from "../../Global";
import { useTranslation } from "react-i18next";

function CardImg(props) {
  const { projet } = props;
  const STATUS = projet.code_statut;
  const { t } = useTranslation("project");

  return (
    <div className="card__image card__box-v1">
      <div className="row">
        <div className="col-lg-7">
          <div className="card__image__header h-250">
            <a href="#">
              <img
                src={asset(projet.photo)}
                alt=""
                className="img-fluid w100 h-auto img-transition"
              />
            </a>
          </div>
        </div>
        <div className="col-lg-5 my-auto">
          <div className="card__image__body h-250">
            <ListGroup variant="flush" className="ml-n2 mt-lg-n4">
              <ListGroup.Item
                as="li"
                className="d-md-flex justify-content-between custom-list-group-item mt-lg-n3 projet-details"
              >
                <div className="fw-bold fs-5">{projet.details?.nom}</div>
                <div className="my-auto mt-4 mt-md-0">
                  <span className="info py-1">{projet.libelle_type}</span>
                </div>
              </ListGroup.Item>
              <ListGroup.Item
                as="li"
                className="d-flex justify-content-between custom-list-group-item projet-details"
              >
                <div className="info">{projet.libelle_statut}</div>
                {(STATUS === VOTING || STATUS === VERIFICATIONINPROGRESS) && (
                  <div>
                    {projet.nbre_interesse > 0 && (
                      <>
                        <BsHeartFill color="#FF0000" />
                        <span className="pl-2 fw-500"> { projet.nbre_interesse } </span>
                        { t("Interested") }
                      </>
                    )}
                  </div>
                )}
              </ListGroup.Item>
              <ListGroup.Item
                as="li"
                className="d-flex justify-content-between custom-list-group-item projet-details"
              >
                <div className="fw-500">
                  <img
                    src="https://reise-assets.fra1.digitaloceanspaces.com/assets/surface.svg"
                    alt=""
                    className="surface"
                  />
                  {projet.superficie} {projet.libelle_unite}
                </div>
                <div>
                  <div className="text-capitalize">
                    <FaMapMarkerAlt size={20} /> {projet.localite}
                  </div>
                </div>
              </ListGroup.Item>
              <ListGroup.Item
                as="li"
                className="d-flex justify-content-between custom-list-group-item projet-details mt-3"
              >
                <div>{t("Minimum investment")} : </div>
                <span className="fw-500">{CurrencyConverter(projet.invest_min)}</span>
              </ListGroup.Item>
              <ListGroup.Item
                as="li"
                className="d-flex justify-content-between custom-list-group-item projet-details"
              >
                <div>{t("Return on Investment")} :</div>
                <span className="fw-500">{projet.roi} % </span>
              </ListGroup.Item>
              <ListGroup.Item
                as="li"
                className="d-flex justify-content-between custom-list-group-item projet-details"
              >
                <div> {t("Investment target")} :</div>
                <span className="fw-500"> {CurrencyConverter(projet.cout)}</span>
              </ListGroup.Item>
              <ListGroup.Item
                as="li"
                className="d-flex justify-content-between custom-list-group-item projet-details"
              >
                <div> {t("Estimated duration")} :</div>
                <span className="fw-500">
                  {projet.duree} {t(projet.libelle_typeduree)}
                </span>
              </ListGroup.Item>
              <ListGroup.Item
                as="li"
                className="custom-list-group-item projet-details mt-3"
              >
                <ProgressBar
                  now={projet.pourcentage_mont_invest}
                  label={`${projet.pourcentage_mont_invest}% Financé`}
                  min={
                    projet.pourcentage_mont_invest > 0 &&
                    projet.pourcentage_mont_invest < 20
                      ? 20
                      : 0
                  }
                />
              </ListGroup.Item>
              <ListGroup.Item
                as="li"
                className="d-flex justify-content-between custom-list-group-item projet-details finance"
              >
                <div>{t("Amount financed")} :</div>
                <span className="fw-500">
                  {CurrencyConverter(projet.montant_invest)}
                </span>
              </ListGroup.Item>
            </ListGroup>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardImg;
