import { useState } from 'react';

export default function useAuth() {

  const getAuth = () => {
    const authString = localStorage.getItem('authUser');
    const userAuth = JSON.parse(authString);
    return userAuth
  };

  const [auth, setAuth] = useState(getAuth());

  const saveAuth = (userAuth) => {
    if(userAuth) {
      localStorage.setItem('authUser', JSON.stringify(userAuth));
    }else {
      localStorage.removeItem('authUser')
    }
    setAuth(userAuth);

  };

  return {
    setAuth: saveAuth,
    auth
  }
}