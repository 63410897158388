import React, { useState } from 'react'
import { LOTISSEMENT, VEFA, ACHAT_REVENTE, LOCATIF } from '../../Global'
import Lotissement from './projectTypes/Lotissement'
import PromoImmo from './projectTypes/PromoImmo'
import Locatif from './projectTypes/Locatif'
import GoogleMaps from '../GoogleMaps'
import EmptyMessage from '../EmptyMessage'
import { Carousel } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { asset } from '../../utils/utils'
import Achatrevent from './projectTypes/AchatRevente'
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

const style = {
    width: "100%",
    height: "400px",
};

const TheProperty = ({ project }) => {
    const { t } = useTranslation("project");
    const aproximite = JSON.parse(project.details?.aproximite) ;
    const lat = parseFloat(project.lat);
    const lng = parseFloat(project.long);
    const markers = [{ lat: lat, lng: lng }];
    const [activeIndex, setActiveIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setActiveIndex(selectedIndex);
    };

    return (
        <>
            {(() => {
                switch (project.code_type) {
                    case LOTISSEMENT:
                        return <Lotissement project={project} />
                    case VEFA:
                        return <PromoImmo project={project} />
                    case ACHAT_REVENTE:
                        return <Achatrevent project={project} />
                    case LOCATIF:
                        return <Locatif project={project} />
                    default:
                        return <h1>DEFAULT</h1>
                }
            })()}
            {/* Points of interest */}
            <div className="single__detail-features">
                <h5 className="text-capitalize detail-heading">
                    {t("Points of interest")}
                </h5>
                {aproximite && aproximite.length > 0 ? (
                    <div className="row">
                        <div className="col-md-6">
                            <div className="single__detail-features-nearby">
                                <h6 className="text-capitalize font-heading text-gray">
                                    {t("Nearby")} :
                                </h6>

                                <ul className="list-unstyled nearby">
                                    {aproximite?.map((item, idx) => (
                                        <li key={idx}>
                                            <span> {item.titre} </span>
                                            <p>{item.distance} km </p>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="single__detail-features-nearby">
                                <h6 className="text-capitalize font-heading text-gray">
                                    {t("Access")} :
                                </h6>
                                <p
                                    className="text-gray"
                                    dangerouslySetInnerHTML={{ __html: project.details?.acces }}
                                ></p>
                            </div>
                        </div>
                    </div>
                ) : (
                    <EmptyMessage message={t("no available information")} />
                )}
            </div>
            {/* maps */}
            <div className="single__detail-features">
                <h5 className="text-capitalize detail-heading">{t("Location")}</h5>
                <GoogleMaps markers={markers} containerStyle={style} />
            </div>
            {/* medias */}
            <div className="single__detail-features">
                <h6 className="text-capitalize detail-heading">{t("Photos")}</h6>
                {project.photos.length > 0 ? (
                    <div className="single__detail-features-video">
                        <Carousel fade indicators={false} activeIndex={activeIndex} onSelect={handleSelect}>
                            {project.photos?.map((img, index) => (
                                <Carousel.Item key={img.id}>
                                    <Zoom>
                                        <img
                                            className="d-block w-100 carousel-image"
                                            src={asset(img.url)}
                                            alt="First slide"
                                        />
                                    </Zoom>
                                </Carousel.Item>

                            ))}
                        </Carousel>

                    </div>
                ) : (
                    <EmptyMessage message={t("no available information")} />
                )}
            </div>
            {/* video */}
            <div className="single__detail-features">
                <h6 className="text-capitalize detail-heading">{t("video")}</h6>
                {project.video_url ? (
                    <div className="single__detail-features-video">
                        <figure className=" mb-0">
                            <div className="home__video-area text-center">
                                <iframe
                                    className="img-fluid video-image"
                                    src={`https://www.youtube.com/embed/${project.video_url}`}
                                    style={{ width: "100%", height: "445px", border: 0 }}
                                    allow="autoplay; encrypted-media"
                                    allowFullScreen
                                    title="video"
                                />
                            </div>
                        </figure>
                    </div>
                ) : (
                    <EmptyMessage message={t("no available information")} />
                )}
            </div>
            {/* plans */}
            <div className="single__detail-features">
                <h5 className="text-capitalize detail-heading">{t("plans")}</h5>
                {project.plans.length > 0 ? (
                    <Carousel fade indicators={false}>
                        {project.plans?.map((img) => (
                            <Carousel.Item key={img.id}>
                                <Zoom activeIndex={activeIndex} onSelect={handleSelect}>
                                    <img
                                        className="d-block w-100 carousel-image"
                                        src={asset(img.url)}
                                        alt="Plan"
                                    />
                                </Zoom>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                ) : (
                    <EmptyMessage />
                )}
            </div>
            {/* tesmonial */}
            {/* <div className="single__detail-features">
            <h6 className="text-capitalize detail-heading">Témoignages</h6>
            <div className="single__detail-features-review">
                {project.temoignages?.map((item) => (

                    <div className="media mt-4">
                        <FaUserCircle size={50} color="#8F8A8A" />
                        <div className="media-body">
                            <h6 className="text-gray mt-0 font-base text-uppercase"> 
                                { item.user?.prenom } { item.user?.nom }
                            </h6>
                            <span className="text-gray mb-4 font-base"> 
                                {formatDate(item.date)} 
                            </span>
                            <p> { item.commentaire } </p>
                        </div>
                    </div>
                ))}
                
            </div> 
        </div>*/}
        </>
    )
}

export default TheProperty
