import { useTranslation } from "react-i18next";
import EmptyMessage from "../../EmptyMessage";
import { Currency, FormatDate } from "../../../utils/utils";


export default function Lotissement({ project }) {
    const { t } = useTranslation("project");

    return (
        <>
            <div className="single__detail-desc">
                <h6 className="text-capitalize detail-heading"> description </h6>
                {project.details?.description ? (
                    <div>
                        <p
                            dangerouslySetInnerHTML={{ __html: project.details?.description }}
                        ></p>
                    </div>
                ) : (
                    <EmptyMessage message={t("no available information")} />
                )}
            </div>
            {/* feathers */}
            <div className="single__detail-features">
                <h5 className="text-capitalize detail-heading">{t("features")}</h5>
                <div className="property__detail-info">
                    <div className="row gx-0">
                        <div className="col-md-6 col-lg-6">
                            <ul className="property__detail-info-list list-unstyled">
                                <li>
                                    <b>{t("Locality")} :</b>
                                    {project.lieu?.localite}
                                </li>
                                <li>
                                    <b> {t("Property area")} :</b>
                                    {project.superficie || t("Info not available")}{" "}
                                    {project.libelle_unite || ""}
                                </li>
                                <li>
                                    <b> {t("Current property title")} :</b>{project.libelle_titre}
                                </li>
                                <li>
                                    <b> {t("NICAD")} :</b>
                                    {project.avec_nicad || t("Info not available")}
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6 col-lg-6">
                            <ul className="property__detail-info-list list-unstyled">
                                
                                <li>
                                    <b>{t("Subdivision authorization number")}:</b>
                                    {project.no_aut_lotir || t("Info not available")}
                                </li>
                                <li>
                                    <b>{t("Final property title")} :</b>
                                    {project.libelle_titre_obtenir || t("Info not available")}
                                </li>
                                <li>
                                    <b> {t("Total number of units")} :</b>
                                    {project.estm_nbre_plle || t("Info not available")}{" "}
                                    {project.libelle_type_unite || ""}
                                </li>
                                <li>
                                    <b> {t("Subdivision authorization date")}:</b>
                                    {FormatDate(project.date_aut_lotir) || t("Info not available")}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="single__detail-desc">
                <h6 className="text-capitalize detail-heading"> {t('Property specifications')} </h6>
                <div>
                    <h6 className="font-base fw-500 text-gray pt-3">
                        {t('Subdivision scheme')}
                    </h6>
                    <p
                        dangerouslySetInnerHTML={{ __html: project.details?.comp_terrains }}
                    ></p>
                </div>
                <div>
                    <h6 className="font-base fw-500 text-gray pt-3"> {t('Type of soil')} </h6>
                    <p dangerouslySetInnerHTML={{ __html: project.details?.nature_sol }}></p>
                </div>
            </div>
        </>
    );
}
