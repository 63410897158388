import { gql } from "@apollo/client";


const GET_LANGUES = gql`
  query getLangues {
    langues {
      id
      code
      libelle
    }
  }
`;

const GET_LANGUES_BY_CODE = gql`
  query getLangue($code: Mixed!) {
    langueByCode(where: { column: "code", value: $code }) {
      id
      code
    }
  }
`;

const GET_PROJECTS_PER_LANGUE = gql`
  query FetchProjectsPerLangueData($langueId: Int!) {
    projetsPerLang(where: { column: IS_PUBLISHED, operator: EQ, value: 1 }) {
      id
      code
      code_statut
      code_type
      libelle_unite
      
      
      libelle_titre(langue_id: $langueId)
      libelle_statut(langue_id: $langueId)
      libelle_type(langue_id: $langueId)
      libelle_type_unite(langue_id: $langueId)

      libelle_typeduree(langue_id: $langueId)
      # typebien(langue_id: $langueId)
      libelle_typebien(langue_id: $langueId)
      libelle_titre_obtenir(langue_id: $langueId)

      details(langue_id: $langueId) {
        nom
        description
        note
        acces
        avec_bail
        avec_tf
        comp_terrains
        nature_sol
        budget_previsionnel
        planning_previsionnel
        equipement
        aproximite
        raisoninvestir
        garanties
      }
      superficie
      cout
      duree
      estm_nbre_plle
      roi
      nb_interesses
      lat
      long
      photo
      no_aut_lotir
      date_aut_lotir
      prix_m2_loti
      nbre_batiment
      progression_actif
      invest_min
      montant_invest
      localite
      lieu_localite
      pourcentage_mont_invest
      nbre_interesse
      created_at
      updated_at
      deleted_at
    }
  }
`;

const GET_PROJETS_PER_LOT = gql`
  query getProjetsPack($ids: Mixed $langueId: Int!) {
    ProjetParLots(hasLots: { column: ID, operator: IN, value: $ids }) {
      id
      code
      photo
      code_statut
      code_type
      libelle_unite
      libelle_titre(langue_id: $langueId)
      libelle_statut(langue_id: $langueId)
      libelle_type(langue_id: $langueId)
      libelle_type_unite(langue_id: $langueId)

      details(langue_id: $langueId) {
        nom
        description
        note
      }
      lieu_localite
      superficie
      cout
      lots {
        id
        titre
        montant
        projet_id
        nombre_parcelle_lot
        valeur_estm
        roi
      }
    }
  }
`;

const GET_DETAILS_PROJECTS_PER_LANGUE = gql`
  query getDetailsProject($code: String!, $langueId: Int!) {
    projetPerLang(code: $code) {
      id
      code
      code_statut
      code_type
      code_unite
      libelle_unite
      saut_investissement
      # new fields
      libelle_typeduree(langue_id: $langueId)
      typebien(langue_id: $langueId)
      libelle_typebien(langue_id: $langueId)
      libelle_titre_obtenir(langue_id: $langueId)
      nature_projet(langue_id: $langueId)

      libelle_statut(langue_id: $langueId)
      libelle_type(langue_id: $langueId)
      libelle_type_unite(langue_id: $langueId)
      libelle_titre(langue_id: $langueId)
      video_url(langue_id: $langueId)
      details(langue_id: $langueId) {
        nom
        description
        note
        acces
        avec_bail
        avec_tf
        comp_terrains
        nature_sol
        budget_previsionnel
        planning_previsionnel
        equipement
        aproximite
        raisoninvestir
        garanties
        etage
      }
      avec_nicad
      superficie
      cout
      duree
      date_debut
		  date_livraison

      estm_nbre_plle
      nbre_batiment
      roi
      nb_interesses
      lat
      long
      photo
      no_aut_lotir
      date_aut_lotir
      prix_m2_loti
      code_statut
      progression_actif
      invest_min
      montant_invest
      localite
      lieu_localite
      pourcentage_mont_invest
      nbre_interesse

      # if the project type is "locatif" or "achat-revente"
      apport_locatif_mens
      valeur_revente
      annee_const

      medias {
        id
        type
        url
        location
        file_name
      }
      photos {
        id
        type
        url
        location
        file_name
      }
      plans {
        id
        type
        url
        location
        file_name
      }
      documents(langue_id: $langueId) {
        id
        type
        url
        location
        file_name
      }
      videos(langue_id: $langueId) {
        id
        type
        url
        location
        file_name
      }
      available_lot {
        id
        titre
        montant
        nombre_parcelle_lot
        valeur_estm
        roi
        terrains {
          id
          num_plle
          superficie
          valeur_estm
        }
      }
      lots {
        id
        titre
        montant
        nombre_parcelle_lot
        valeur_estm
        roi
        is_available
        is_confirmed
        reservable_users_id
        terrains {
          id
          num_plle
          superficie
          valeur_estm
        }
      }
      lots_avec_terrain {
        id
        titre
        montant
        nombre_parcelle_lot
        valeur_estm
        roi
        is_available
        is_confirmed
        reservable_users_id
        terrains {
          id
          num_plle
          superficie
          valeur_estm
        }
      }
      pivot_progressions {
        libelle(langue_id: $langueId)
        remarque
        remarque_en
        created_at
        updated_at
        documents {
          id
          type
          url
          location
          file_name
        }
      }
      terrains {
        id
        num_plle
        superficie
        valeur_estm
      }
      investissements {
        id
        montant
        note
        created_at
        updated_at
        deleted_at
      }
      lieu {
        id
        localite
        created_at
        updated_at
      }
      temoignages {
        id
        commentaire
        date
        user {
          id
          nom
          prenom
          full_name
          whatsApp
        }
      }
      faqs {
        id
        details(langue_id: $langueId) {
          id
          question
          reponse
        }
        created_at
        updated_at
      }

      created_at
      updated_at
      deleted_at
    }
  }
`;

const GET_PARAMS = gql`
  query getParams($type: Mixed) {
    parametres(where: { column: TYPE, operator: EQ, value: $type }) {
      id
      libelle
      code
      type
    }
  }
`;

const GET_PARAM_BY_CODE = gql`
  query getParam($type: Mixed, $code: Mixed) {
    parametreByCode(
    where: {
      AND: [{ column: TYPE, value: $type }, { column: CODE, value: $code }]
    }
  ) {
    id
    originalLibelle
    code
    type
  }

  }
`;


const GET_PARAMS_PER_LANGUE = gql`
  query getParams($type: String, $langueId: Int!) {
    parametresPerLang(type: $type) {
      id
      code
      type
      libelle(langue_id: $langueId)
    }
  }
`;

const GET_PAYS = gql`
  query fetchPaysData($langueId: Int!) {
    paysPerLangue {
      id
      code
      details(langue_id: $langueId) {
        nom
      }
      created_at
      updated_at
      deleted_at
    }
  }
`;

const GET_LIEUX = gql`
  query getLieux($id: Int) {
    lieux(id: $id) {
      id
      localite
    }
  }
`;

const CREATE_USER = gql`
  mutation addUser(
    $nom: String!
    $prenom: String!
    $telephone: String
    $email: String!
    $password: String!
    $langue: String!
    $type: String
    $etre_informe: Boolean
    $condition: Boolean
    $indTel: String
  ) {
    createUser(
      nom: $nom
      prenom: $prenom
      telephone: $telephone
      email: $email
      password: $password
      langue:  $langue
      type: $type
      etre_informe: $etre_informe
      condition: $condition
      indTel: $indTel
    ) {
      id
      nom
      prenom
      full_name
      telephone
      email
      password
      type
      etre_informe
      condition
      indTel
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser(
    $id: ID!
    $nom: String!
    $prenom: String!
    $telephone: String
    $adresse: String
    $pays_id: Int
    $ville: String
    $indTel: String
    $langue:  String!
  ) {
    updateUser(
      id: $id
      nom: $nom
      prenom: $prenom
      telephone: $telephone
      adresse: $adresse
      pays_id: $pays_id
      ville: $ville
      indTel: $indTel
      langue:  $langue
    ) {
      id
      nom
      prenom
      full_name
      telephone
      adresse
      ville
      indTel
      langue
    }
  }
`;

const GET_USER = gql`
  query getUser($id: ID!, $email: String) {
    user(id: $id, email: $email) {
      id
      nom
      prenom
      full_name
      email
      telephone
      custom_telephone
      indTel
      adresse
      ville
      pays_id
    }
  }
`;

const LOGIN_MUTATION = gql`
  mutation loginUser($username: String!, $password: String!) {
    login(input: { username: $username, password: $password }) {
      access_token
      refresh_token
      expires_in
      token_type
      user {
        id
        email
        nom
        prenom
        full_name
        telephone
        adresse
        ville
        email_verified_at
        created_at
        updated_at
      }
    }
  }
`;

const GET_LOT_DATA = gql`
  query fetchLotData($id: Int) {
    lot(id: $id) {
      id
      titre
      montant
      nombre_parcelle_lot
      valeur_estm
      projet_id
      created_at
      terrains {
        id
        num_plle
        superficie
        valeur_estm
        created_at
      }
    }
  }
`;

const GET_LANDS_LIST = gql`
  query fetchLandsList($lot_id: Int!) {
    terrains(lot_id: $lot_id) {
      id
      num_plle
      superficie
      valeur_estm
      projet_id
      lot_id
      created_at
      updated_at
      deleted_at
    }
  }
`;

const GET_LOTS_DATA = gql`
  query fetchLotsData {
    lots {
      id
      titre
      montant
      nombre_parcelle_lot
      valeur_estm
      created_at
      terrains {
        id
        num_plle
        superficie
        valeur_estm
        created_at
      }
    }
  }
`;

const GET_INVESTMENT_PACK = gql`
  query getInvestmentPack($ids: Mixed) {
    lots(where: { column: ID, operator: IN, value: $ids }) {
      id
      titre
      montant
      nombre_parcelle_lot
      valeur_estm
      roi
      terrains {
        id
        num_plle
        superficie
        valeur_estm
        projet_id
        created_at
      }
    }
  }
`;

const CREATE_INVESTMENT = gql`
  mutation cerateInvestment(
    $montant: Int
    $statut: String
    $type: String
    $user_id: Int
    $projet_id: Int
    $pris_connaissance_CGU: Boolean
    $certifie_fonds_investissement: Boolean
    $accepter_les_risques: Boolean
    $elaboration_previsionnel_roi_duree: Boolean
  ) {
    createInvestissement(
      input: {
        montant: $montant
        statut: $statut
        type: $type
        user_id: $user_id
        projet_id: $projet_id
        pris_connaissance_CGU: $pris_connaissance_CGU,
        certifie_fonds_investissement: $certifie_fonds_investissement,
        accepter_les_risques: $accepter_les_risques
        elaboration_previsionnel_roi_duree: $elaboration_previsionnel_roi_duree
      }
    ) {
      montant
      statut
      type
      user_id
      projet_id
      pris_connaissance_CGU
      certifie_fonds_investissement
      accepter_les_risques
      elaboration_previsionnel_roi_duree
    }
  }
`;
const CREATE_INTERESSER = gql`
  mutation createInteresser($projet_id: Int!, $user_id: Int! $montant: Int) {
    createInteresse(projet_id: $projet_id, user_id: $user_id, montant: $montant) {
      id
      projet_id
      user_id
      montant
    }
  }
`;

const GETINTERESSE_BY_PROJET_AND_USER = gql`
  query getInteresseByProjetAndUser($projet_id: Mixed!, $user_id: Mixed!) {
    interesseByProjetAndUser(
      where: {
        AND: [
          { column: PROJET_ID, operator: EQ, value: $projet_id }
          { column: USER_ID, operator: EQ, value: $user_id }
        ]
      }
    ) {
      id
      projet_id
      user_id
      montant
    }
  }
`;

const GET_PANIER = gql`
  query getPanier($user_id: Int!) {
    paniers(
      where: { column: STATUT, operator: EQ, value: "INPROGRESS" }
      where: { column: USER_ID, operator: EQ, value: $user_id }
    ) {
      id
      lot_id
      user_id
      created_at
      updated_at
    }
  }
`;

const CREATE_PANIER = gql`
  mutation createPanier($lot_id: Int!, $user_id: Int!) {
    createPanier(lot_id: $lot_id, user_id: $user_id) {
      lot_id
      user_id
    }
  }
`;

const DELETE_PANIER = gql`
  mutation deletePanier($lot_id: Int!, $user_id: Int!) {
    deletePanier(lot_id: $lot_id, user_id: $user_id)
  }
`;

const CREATE_OR_UPDATE_PANIERS = gql`
  mutation createOrUpdatePaniers($lot_ids: [ID]!, $user_id: Int!) {
    createOrUpdatePaniers(input: { lot_ids: $lot_ids, user_id: $user_id })
  }
`;

const DELETE_INTERESSE = gql`
  mutation deleteIntrest($id: ID!) {
    deleteInteresse(id: $id) {
      id
    }
  }
`;

const GET_TYPES = gql`
  query getTypes($langueId: Int!) {
    types {
      id
      code
      libelle(langue_id: $langueId)
    }
  }
`;


export {
  GET_LANGUES,
  GET_LANGUES_BY_CODE,
  GET_PROJECTS_PER_LANGUE,
  GET_DETAILS_PROJECTS_PER_LANGUE,
  GET_PARAMS,
  GET_PARAMS_PER_LANGUE,
  GET_PARAM_BY_CODE,
  GET_PAYS,
  GET_LIEUX,
  LOGIN_MUTATION,
  CREATE_USER,
  UPDATE_USER,
  GET_INVESTMENT_PACK,
  CREATE_INVESTMENT,
  GET_LOTS_DATA,
  GET_LOT_DATA,
  GETINTERESSE_BY_PROJET_AND_USER,
  CREATE_INTERESSER,
  DELETE_INTERESSE,
  GET_PANIER,
  CREATE_PANIER,
  DELETE_PANIER,
  CREATE_OR_UPDATE_PANIERS,
  GET_PROJETS_PER_LOT,
  GET_LANDS_LIST,
  GET_USER,
  GET_TYPES,

};
