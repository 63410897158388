import React from 'react'
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function Loading() {

    const { t } = useTranslation('project')
  
    return (
        <div className="d-flex justify-content-center my-5">
            <div>
                <Spinner animation="grow" variant="primary" className="p-4" />
                <h6 className="ml-n5 mb-5 pt-3"> {t('loading')} </h6>
            </div>
        </div>
    );
}

export default Loading;