import React from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BsFillHeartFill, BsWhatsapp } from "react-icons/bs";
import { FaShare } from "react-icons/fa";
import { ImWhatsapp } from "react-icons/im";
import { Link } from "react-router-dom";
import { SimpleShareButtons } from "react-simple-share";

const LotAside = () => {
  const { t } = useTranslation("project");

  const whatsAppShareButton = () => {
    window.open(
      `https://api.whatsapp.com/send/?phone=&text=${window.location.href}`,
      "mozillaWindow",
      "popup"
    );
  };

  return (
    <div className="products__filter mb-30">
      <div className="products__filter__group">
        <div className="products__filter__header">
          <h5 className="text-center">Que voulez vous faire ?</h5>
        </div>

        <div className="products__filter__body">
          <Form.Group className="mb-4">
            <Button
              variant="warning"
              className="btn-base-warning btn-block"
              onClick={() => console.log("I am interested in this project")}
            >
              Je veux réserver mes terrains terrain
            </Button>
          </Form.Group>
          <Form.Group className="mb-4">
            <a
              href="https://wa.me/221770952155"
              target="_blank"
              rel="noreferrer"
              className="nav-link whatsApp "
            >
              <BsWhatsapp /> {t("Contact us by WhatsApp")}
            </a>
          </Form.Group>
            <Form.Group className="mb-4">
                <Link className="nav-link whatsApp">
                  <BsFillHeartFill />  Ajouter aux favoris
                </Link>
            </Form.Group>
          <Form.Group className="d-grid contact">
            <Dropdown>
              <Dropdown.Toggle variant="link" id="dropdown-share">
                <FaShare /> Partagez cette annonce
              </Dropdown.Toggle>
              <Dropdown.Menu className="rounded-0">
                <Dropdown.Item className="d-flex justify-content-center align-items-center">
                  <SimpleShareButtons
                    whitelist={["Facebook", "Twitter", "LinkedIn"]}
                  />

                  <ImWhatsapp
                    className="ml-2 mt-2"
                    onClick={whatsAppShareButton}
                    size={25}
                    color="#25d366"
                  />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
        </div>
      </div>
    </div>
  );
};

export default LotAside;
