import { useQuery } from '@apollo/client';
import React, {useState} from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import CardImg from '../../components/lots/CardImg';
// import FilterProject from '../../components/lotsDetails/FilterProject';
import LotsDescription from '../../components/lots/LotsDescription';
import LotAside from '../../components/lots/LotAside';
// import SimilarLots from '../../components/lotsDetails/SimilarLots';
import { GET_LOTS_DATA, GET_LOT_DATA } from '../../Queries';

const LotsDetails = () => {
  const {id} = useParams();

  const [detailsLots, setDetailsLots] = useState({});
  const [lots, setLots] = useState([]);

  const { loading, error } = useQuery(GET_LOT_DATA, {
    variables: { id: parseInt(id) },
    onCompleted: (data) => {
      setDetailsLots(data.lot);
    },
  });

  const { loading: loading2, error: error2 } = useQuery(GET_LOTS_DATA, {
    onCompleted: (data) => setLots(data.lots),
  });

   if (loading) return 'Loading...';
   if (error) return `Error! ${error.message}`;

  return (
    <div className="container custom-container">
      <CardImg detailsLots={detailsLots} />
      <Row className="gx-4">
        <Col lg={8}>
          <LotsDescription />
        </Col>
        <Col lg={4}>
          <LotAside />
        </Col>
      </Row>
      {/* <SimilarLots /> */}
    </div>
  );
};

export default LotsDetails;
