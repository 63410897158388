import React from "react";
import Card from "react-bootstrap/Card";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import ListGroup from "react-bootstrap/ListGroup";
import ProgressBar from "react-bootstrap/ProgressBar";
import { FaMapMarkerAlt } from "react-icons/fa";
import { BsHeartFill } from "react-icons/bs";

import { Currency, asset } from "../utils/utils";
import { VOTING, VERIFICATIONINPROGRESS } from "../Global";
import { useTranslation } from "react-i18next";

export default function ListItems({ width, projet, styled = false }) {
  const STATUS = projet.code_statut;
  const { t } = useTranslation("project");

  const status = {
    fontSize: styled ? "10px" : "12px",
    padding: styled ? "2px 2px" : "2px 5px",
  };

  const cardImgOverlay = {
    padding: styled ? "1rem 0.25rem" : "1rem", 
  };


  return (
    <Card style={{ width: width }} className="position-relative">
      <Card.Img
        className="image-projet"
        variant="top"
        src={asset(projet.photo)}
      />
      <Card.ImgOverlay className="d-flex justify-content-between h-20" style={cardImgOverlay}>
        <Card.Text as="div" className="bg-primary text-white text-uppercase custom-subtile" style={status}>
          {projet.libelle_type}
        </Card.Text>
        <Card.Text as="div" className="bg-primary text-white text-uppercase custom-subtile" style={status}>
          {projet.libelle_statut}
        </Card.Text>
      </Card.ImgOverlay>
      <Card.Body>
        <ListGroup variant="flush" className=" ml-n2 mt-n2">
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between custom-list-group-item"
          >
            <div className="superficie fw-500">
              {projet?.superficie}  {projet?.libelle_unite}
            </div>
            {(STATUS === VOTING || STATUS === VERIFICATIONINPROGRESS) && (
              <div>
                {projet.nbre_interesse > 0 && (
                  <>
                    <BsHeartFill color="#FF0000" />
                    <span className="pl-2 fw-500">
                      {projet.nbre_interesse || 0}
                    </span>{" "}
                    {t("Interested")}
                  </>
                )}
              </div>
            )}
          </ListGroup.Item>
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between custom-list-group-item"
          >
            <div>
              {" "}
              <FaMapMarkerAlt size={15} /> {projet.localite}
            </div>
          </ListGroup.Item>
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between custom-list-group-item"
          >
            <div className="fw-500">{projet.details?.nom}</div>
          </ListGroup.Item>
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between custom-list-group-item text-truncate"
          >
            <div className="text-truncate">{t("Minimum investment")} :</div>
            <span className="fw-500">{Currency(projet.invest_min)}</span>
          </ListGroup.Item>

          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between custom-list-group-item"
          >
            <div> {t("Return on Investment")} :</div>
            <span className="fw-500">{projet.roi}%</span>
          </ListGroup.Item>
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between custom-list-group-item"
          >
            <div> {t("Estimated duration")} :</div>
            <span className="fw-500">
              {projet.duree} {t(projet.libelle_typeduree)}
            </span>
          </ListGroup.Item>
          <ListGroup.Item as="li" className="custom-list-group-item">
            <ProgressBar
              now={projet.pourcentage_mont_invest}
              label={`${projet.pourcentage_mont_invest}% Financé`}
              min={
                projet.pourcentage_mont_invest > 0 &&
                  projet.pourcentage_mont_invest < 20
                  ? 20
                  : 0
              }
            />
          </ListGroup.Item>
        </ListGroup>
      </Card.Body>
      <Card.Footer className="custom-card-footer">
          <Link
            to={`/projets/${projet.code}`}
            className="text-uppercase text-decoration-none stretched-link text-center text-white"
          >
            {t("Project details")}
          </Link>
        
      </Card.Footer>
    </Card>
  );
}

ListItems.propTypes = {
  projet: PropTypes.object,
};

ListItems.defaultProps = {
  projet: {},
};
