
const OPENFORINVESTMENT = "OPEN_FOR_INVESTMENT";
const VOTING = "VOTING";
const VERIFICATIONINPROGRESS = "VERIF_IN_PROGRESS";
// const FULLINVESTMENT = "FULL_INVESTMENT";
// const CANCELED = "CANCELED";


export const LOTISSEMENT = "LOTISSEMENT"
export const VEFA = "VEFA"  // PROMOTION IMMOBILIERE
export const ACHAT_REVENTE = "ACHAT-REVENTE"
export const LOCATIF = "LOCATIF"







export {
    OPENFORINVESTMENT,
    VOTING,
    VERIFICATIONINPROGRESS,
}