import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const NotFound = () => {

    const { t } = useTranslation('404')
    return (
        <div className="wrap-notfound bg-theme-v8 full-height">
            <div className="container h-100 ">
                <div className="row align-items-center h-100">
                    <div className="col mx-auto">
                        <div className="notfound-page ">
                            <h1>
                                404
                            </h1>
                            <p>
                                {t('Page not found!')}
                                <br />
                                <span>
                                    {t('Sorry, but the page you were trying to view does not exist')}
                                </span>
                                <br />
                            </p>
                            <div className="mt20">
                                <Link className="base-navlink p-2" to="/">
                                    <i className="fa fa-reply pr-1">
                                    </i>
                                    {t('Back to home')}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotFound
