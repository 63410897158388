import { Accordion } from "react-bootstrap";
import EmptyMessage from "../EmptyMessage";

export default function Faq({faqs}) {
    return (
        <Accordion defaultActiveKey={0} flush alwaysOpen className="mb-5 container">
            {faqs?.length ? 
            (faqs?.map((faq, key) => (

                <Accordion.Item eventKey={key} key={faq.id}>
                    <Accordion.Header className=" text-gray">
                        <div dangerouslySetInnerHTML={{ __html: faq.details?.question}}></div>
                    </Accordion.Header>
                    <Accordion.Body>
                    <div dangerouslySetInnerHTML={{ __html: faq.details?.reponse}}></div>
                    </Accordion.Body>
                </Accordion.Item>
            ))) :
                <EmptyMessage />
            }
            
        </Accordion>
    )
}