import React, { useRef, useEffect} from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const ConfirmationSelected = () => {
  const { t } = useTranslation('invest')
  // const ref = useRef(null)

  // useEffect(() => {
  //   ref.current?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
  // })

  return (
    <div className="px-5">
      <h5 className="text-uppercase fw-bold font-base text-center text-blue pt-5">
        {t('CONFIRMATION OF YOUR SUBSCRIPTION')}
      </h5>
      <p
        className="text-center py-4 px-5 text-notification investment-pack"
        dangerouslySetInnerHTML={{ __html: t('confirmation of the reservation') }}
      ></p>

      <div className="d-flex justify-content-center py-4">
        <Link to="/" className="notifation base-navlink">
          {t('RETURN TO HOME PAGE')}
        </Link>
      </div>
    </div>
  )
}

export default ConfirmationSelected