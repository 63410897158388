import { useState, useEffect } from "react";
import defaultImage from "../images/logo.png";
import { useTranslation } from "react-i18next";
import APP_ENV from "../config/Env";
import { currency$ } from "./rxjs";

const Currency = (money, devise = "XOF") => {

  if (money) {
    if (!Number.isInteger(money)) {
      money = parseInt(money.replaceAll(" ", ""));
    }

    return new Intl.NumberFormat("fr-FR", {
      style: "currency",
      currency: devise,
    }).format(money);
  }

  return new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: devise,
  }).format(0);
};

// Coverting devise XOF to EUR and vice versa with the rate of 1 EUR = 655.957 XOF 
const CurrencyConverter = (money,) => {

  const [devise, setDevise] = useState("XOF");
  const [rate, setRate] = useState(null);
  
  useEffect(() => {
    currency$.subscribe((currency) => {
      setDevise(currency?.code || "XOF");
      setRate(currency?.originalLibelle);
    });
  }, []);

  if (money) {
    if (!Number.isInteger(money)) {
      money = parseInt(money.replaceAll(" ", ""));
    }

    if (devise === "EUR" && rate) {
      return new Intl.NumberFormat("fr-FR", {
        style: "currency",
        currency: "EUR",
      }).format(money / rate);
    } else {
      return new Intl.NumberFormat("fr-FR", {
        style: "currency",
        currency: "XOF",
      }).format(money);
    }
  }

  return new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: devise,
  }).format(0);
};




const FormatDateString = (date) => {
  const { t } = useTranslation("date");

  let options = { year: "numeric", month: "long" };
  let dateFormat = new Date(date);
  if (date) {
    return dateFormat.toLocaleDateString(t('code'), options);
  }

  return;
};

const FormatDate = (date) => {
  const { t } = useTranslation("date");
  let options = { year: "numeric", month: "numeric", day: "numeric" };
  let dateFormat = new Date(date);
  if (date) {
    return dateFormat.toLocaleDateString(t('code'), options);
  }

  return;
};

const asset = (path) => {
  if (path && APP_ENV.asset_url !== APP_ENV.baseURL) {
    return `${APP_ENV.asset_url}/${path}`;
  } else if (path) {
    return `${APP_ENV.baseURL}/${path.replace("public", "storage")}`;
  }

  return defaultImage;

};

const staticAsset = (fileName) => `${APP_ENV.asset_static_url}/${fileName}`;

// The wordpress base url's
const wpBaseURL = (pathname = "") => `${APP_ENV.wp_baseURL}/${pathname}`;

export {
  Currency,
  CurrencyConverter,
  FormatDateString,
  FormatDate,
  asset,
  staticAsset,
  wpBaseURL,
};
