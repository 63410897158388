import React, { useState } from 'react';

import {
    Row,
    Col,
    Nav,
    Tab,
    Modal,
    Button,
    ToggleButton,
    Dropdown,
} from 'react-bootstrap';

import { FiFilter } from "react-icons/fi";
import ListItems from './ListItems';
import './tabs.css';

import { useQuery } from '@apollo/client';
import { GET_LIEUX, GET_PARAMS_PER_LANGUE, GET_PROJECTS_PER_LANGUE, GET_TYPES } from "../Queries";
import MultiRangeSlider from './multiRangeSlider/multiRangeSlider';

import GoogleMaps from './GoogleMaps';
import { FaSortAmountDown, FaSortAmountUp } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import Loading from './loading';
import { useSearchParams } from 'react-router-dom';


const containerStyle = {
    width: '100%',
    height: '100%'
};

export default function Tabs() {
    const { t } = useTranslation('project')
    const [projets, setProjets] = useState([]);
    const [status, setStatus] = useState([]);
    const [types, setTypes] = useState([]);
    const [localite, setLocalite] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [showCurrncy] = useState(true);
    const [minInvestMin, setMinInvestMin] = useState(0);
    const [maxInvestMin, setMaxInvestMin] = useState(1000);
    const investMinStep = 1000000;
    const roiStep = 5;
    const [minRoi, setMinRoi] = useState(0);
    const [maxRoi, setMaxRoi] = useState(100);
    const [markers, setMarkers] = useState([]);
    const [arrayFilter] = useState({
        code_statut: [],
        lieu_localite: [],
        invest_min: [],
        roi: [],
        code_type: []
    });

    const [show, setShow] = useState(false);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [sortBy, setSortBy] = useState('');
    const [order, setOrder] = useState(true);

    const [search, setSearch] = useSearchParams()

  

    const sortable = {
        'Récemment ajouté': 'created_at',
        'Retour sur investissement': 'roi',
        'Investissement minimum': 'invest_min',
        'Durée estimée': 'duree',
    }

    const { loading, error } = useQuery(GET_PROJECTS_PER_LANGUE, {
        variables: { langueId: parseInt(localStorage.getItem('langueId')) },
        onCompleted: data => {
            setProjets(data?.projetsPerLang)
            getLatLng(data?.projetsPerLang)
            setSortBy(t('Récemment ajouté'))
            handlerSort('created_at', order, data?.projetsPerLang)
            setMaxInvestMin(getNextMultiple(Math.max(...data?.projetsPerLang.map(o => o.invest_min)), investMinStep) ?? 10000000)
            setMinInvestMin(getPreviousMultiple(Math.min(...data?.projetsPerLang.map(o => o.invest_min)), investMinStep) ?? 0)
            setMaxRoi(getNextMultiple(Math.max(...data?.projetsPerLang.map(o => o.roi)), roiStep) ?? 100)
            setMinRoi(getPreviousMultiple(Math.min(...data?.projetsPerLang.map(o => o.roi)), roiStep) ?? 0)
            filterProjects(data?.projetsPerLang)

        },
        // fetchPolicy: "no-cache",
    });

    useQuery(GET_PARAMS_PER_LANGUE, {
        variables: {
            type: "Statut",
            langueId: parseInt(localStorage.getItem('langueId'))
        },
        onCompleted: data => setStatus(data.parametresPerLang),
        // fetchPolicy: "no-cache",
    });

    useQuery(GET_LIEUX, {
        variables: {},
        onCompleted: data => setLocalite(data.lieux)
    });

    useQuery(GET_TYPES, {
        variables: {
            langueId: parseInt(localStorage.getItem('langueId'))
        },
        onCompleted: (data) => {
            setTypes(data.types)
            // Create an array of refs with the length of `numCheckboxes`
            
            if(search.get('type')) {
                const type = data?.types.find(x => x.code === search.get('type').toUpperCase())
                const e = {currentTarget: {checked: true, value: type?.code}}
                handleChange('code_type', 'type'+type?.id, e)
            }
        },
        // fetchPolicy: "no-cache",
    });



    function handlerSort(value, sort = order, data = filtered,) {
        let sortData

        if (value === 'created_at') {
            sort = !sort
        }
        if (sort) {
            sortData = [...data].sort((a, b) => {
                return a[value] > b[value] ? 1 : -1;
            })
            // sortData = [...sortData].reverse()
        } else {
            sortData = [...data].sort((a, b) => {
                return a[value] > b[value] ? 1 : -1;
            }).reverse()
        }
        setFiltered(sortData)
        setProjets(sortData)
    }

    const handleChange = (key, id, e) => {
        const selected = selectedCheckboxes;

        // Find index
        const findIdx = selected.indexOf(id);
        // Index > -1 means that the item exists and that the checkbox is checked
        // and in that case we want to remove it from the array and uncheck it
        if (findIdx > -1) {
            selected.splice(findIdx, 1);
        } else {
            selected.push(id);
        }

        setSelectedCheckboxes(selected)

        const { checked, value } = e.currentTarget

        if (checked) {
            arrayFilter[key].push(e.currentTarget.value)
        } else {
            arrayFilter[key] = arrayFilter[key].filter((x) => x !== value)
        }
        filterProjects()
    }
    
    

    const handleRange = (key, min, max) => {
        // let first_result = filterProjects()

        if (!((key === 'roi' && min === 0 && max === 100)
            ||
            (key === 'invest_min' && min === minInvestMin && max === maxInvestMin)
        )) {

            let result = filtered.filter(projet => projet[key] >= min && projet[key] <= max)

            setFiltered(result)
        }
    }

    function filterProjects(data = projets) {
        let final_result = []
        let result = data
        let haveFilterChecked = false

        Object.keys(arrayFilter).map(key => {
            if (arrayFilter[key].length > 0) {
                haveFilterChecked = true
                result = result.filter(projet => arrayFilter[key].includes(projet[key]))
            }

            final_result = result
            return 1;
        });

        haveFilterChecked ? setFiltered(final_result) : setFiltered(data)

        return final_result
    }

    function getTotalLength() {
        let length = 0
        Object.keys(arrayFilter).map(key => length += arrayFilter[key].length)

        return length
    }

    function checkIfresultContain(key, value) {
        let result = projets

        if (arrayFilter['code_statut'].length > 0) {
            result = result.filter(projet => arrayFilter['code_statut'].includes(projet['code_statut']))
        }
        if (arrayFilter['code_type'].length > 0) {
            result = result.filter(projet => arrayFilter['code_type'].includes(projet['code_type']))
        }

        var found = false;
        for (var i = 0; i < result.length; i++) {
            if (result[i][key] == value) {
                found = true;
                break;
            }
        }

        return found
    }

    function getLatLng(projects) {
        let array = [];
        for (const project of projects) {
            const marker = {
                lat: parseFloat(project.lat),
                lng: parseFloat(project.long),
            };
            array.push(marker)
        }
        setMarkers(array)
    }

    function getNextMultiple(x, step) {
        if (x) {
            return Math.ceil(x / step) * step;
        }

        return null;
    }

    function getPreviousMultiple(x, step) {
        if (x) {
            return x - Math.ceil(x % step);
        }
        return null;
    }

    if (loading) return <Loading />;
    if (error) return `Error! ${error.message}`;

    return (
        <div className="tabs__custom-v2 ">

            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Nav variant="pills" className=" myTab">
                    <Nav.Item className="d-block list-inline-item mr-auto">
                        <span className="title-text font-base"> {t('Sort by')} </span>

                        <Dropdown className="d-inline mx-2">
                            <Dropdown.Toggle id="dropdown-autoclose-true sort-by" className="px-2 title-text text-dark fw-400 font-base text-decoration-none">
                                {sortBy === '' ? t('Récemment ajouté') : t(sortBy)}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {Object.keys(sortable)?.map(key => (

                                    <Dropdown.Item key={key}
                                        onClick={() => {
                                            setSortBy(key)
                                            handlerSort(sortable[key])
                                        }}
                                    >
                                        {t(key)}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                        {order ? <FaSortAmountUp className='cursor' title="Trier dans l'ordre croissant" onClick={() => {
                            setOrder(false)
                            handlerSort(sortable[sortBy], false)
                        }} /> : <FaSortAmountDown className='cursor' title="Trier dans l'ordre décroissant" onClick={() => {
                            setOrder(true)
                            handlerSort(sortable[sortBy], true)
                        }} />}

                    </Nav.Item>
                    <Nav.Item className="nav-item mr-md-5 mr-2 nav-item">
                        <h5 className="total-project mt-2 text-uppercase "> {projets.length} {t('projects')} </h5>
                    </Nav.Item>
                    <Nav.Item className="nav-item mr-3">
                        <Button className="custom-btn-modal" onClick={() => setShow(true)}>
                            {t('Filters')} <FiFilter /> {getTotalLength()}
                        </Button>
                    </Nav.Item>
                    <Nav.Item className="nav-item">
                        <Nav.Link eventKey="first" className='nav-link pe-auto'>
                            <span className="fa fa-th-list"></span>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="nav-item px-2 v">
                        <Nav.Link eventKey="second" className='nav-link pe-auto'>
                            <i className="fa fa-map-marker"></i>
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
                <Tab.Content className='mt-5'>
                    <Tab.Pane eventKey="first">
                        <Row>
                            {filtered?.map(item => (
                                <Col className="mb-5" lg={4} key={item.id}>
                                    <ListItems projet={item} />
                                </Col>
                            ))}
                        </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                        <Row>
                            <Col lg={6} className="mb-5">
                                <GoogleMaps
                                    markers={markers}
                                    containerStyle={containerStyle}
                                />
                            </Col>
                            <Col lg={6}>
                                <Row className="gx-1">
                                    {filtered?.map(item => (
                                        <Col className="mb-5" lg={6} key={item.id}>
                                            <ListItems projet={item} styled={true} />
                                        </Col>
                                    ))}
                                </Row>
                            </Col>
                        </Row>
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>

            {/* Modal filter */}

            <Modal
                className="custom-modal-lg"
                size="lg"
                show={show}
                onHide={() => setShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        {t('Filters')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <h6 className="investment-status">
                            {t('Project type')}
                        </h6>
                        {types?.map((item, index) => (
                            <Col key={index}>
                                <ToggleButton
                                    className="custom-checkbox text-uppercase"
                                    id={'type' + item.id}
                                    name="type"
                                    type="checkbox"
                                    variant="outline-secondary"
                                    checked={selectedCheckboxes.includes('type' + item.id)}
                                    value={item.code}
                                    onChange={(e) => handleChange('code_type', 'type' + item.id, e)}
                                    style={types.length === index + 1 ? { minWidth: "max-content" } : {}}
                                >
                                    {item.libelle}
                                </ToggleButton>
                            </Col>
                        ))}

                    </Row>
                    <Row>
                        <h6 className="investment-status"> {t('Investment status')}  </h6>
                        {status?.map((item, index) => (
                            <Col key={index}>
                                <ToggleButton
                                    className="custom-checkbox"
                                    id={'status' + item.id}
                                    name="status"
                                    type="checkbox"
                                    variant="outline-secondary"
                                    checked={selectedCheckboxes.includes('status' + item.id)}
                                    value={item.code}
                                    onChange={(e) => handleChange('code_statut', 'status' + item.id, e)}
                                    style={status.length === index + 1 ? { minWidth: "max-content" } : {}}
                                >
                                    {item.libelle}
                                </ToggleButton>
                            </Col>
                        ))}

                    </Row>

                    <Row gap={0}>
                        <h6 className="investment-status"> {t('Locality')} </h6>
                        {localite?.map((item, index) => (
                            <Col xs={6} lg={3} key={index}>
                                <ToggleButton
                                    className="custom-checkbox localite"
                                    id={'localite' + item.id}
                                    type="checkbox"
                                    variant="outline-secondary"
                                    checked={selectedCheckboxes.includes('lieu_localite' + item.id)}
                                    value={item.localite}
                                    disabled={arrayFilter['code_statut'].length > 0 ? !checkIfresultContain('lieu_localite', item.localite) : false}
                                    onChange={(e) => handleChange('lieu_localite', 'lieu_localite' + item.id, e)}
                                >
                                    {item.localite}
                                </ToggleButton>
                            </Col>
                        ))}
                    </Row>
                    <Row gap={4} className="mb-5">
                        <h6 className="investment-status"> {t('Minimum investment')} </h6>
                        <div className="form-group">
                            <MultiRangeSlider
                                min={minInvestMin}
                                max={maxInvestMin}
                                step={investMinStep}
                                showCurrncy={showCurrncy}
                                onChange={({ min, max }) => handleRange('invest_min', min, max)}
                            />
                        </div>
                    </Row>
                    <Row gap={3} className="mb-5">
                        <h6 className="investment-status"> {t('Return on Investment')}</h6>
                        <div className="form-group">
                            <MultiRangeSlider
                                min={minRoi}
                                max={maxRoi}
                                step={roiStep}
                                onChange={({ min, max }) => handleRange('roi', min, max)}
                            />
                        </div>
                    </Row>
                </Modal.Body>
            </Modal>

            {filtered.length === 0 && (
                <p className="text-center p-5"> {t('No result found')} </p>
            )}
        </div>
    )
}