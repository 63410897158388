import React, { useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";
import APP_ENV from "../config/Env";
import Cookies from "universal-cookie";

const VerifyEmail = (props) => {

    const cookies = new Cookies();
    const [isEmailVerified, setIsEmailVerified] = useState(false);
    const { auth } = useAuth();

    const checkUserToken = () => {

        if (auth && auth.user.email_verified_at === null) {

            setIsEmailVerified(false);
            const current = new Date();
            const nextYear = new Date();
            nextYear.setFullYear(current.getFullYear() + 1);
        
            cookies.set("fromWebsite", true, {
                domain: APP_ENV.domain,
                expires: nextYear,
            });

            return window.location.href = APP_ENV.baseURL;
        }
        setIsEmailVerified(true);
    }


    useEffect(() => {
        checkUserToken();
    }, [isEmailVerified]);


    return (
        <React.Fragment>
            {
                isEmailVerified ? props.children : null
            }
        </React.Fragment>
    );
}
export default VerifyEmail;