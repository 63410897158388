import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ButtonInterested = (props) => {
  const { t } = useTranslation("project");
  return (
    <>
        <Button
          variant="warning"
          className="btn-base-warning btn-block"
          onClick={() => props.handleShowModalInteresse()}
        >
          {t("I am interested in this project")}
        </Button>
    </>
  );
};

export default ButtonInterested;
