import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Col, Row } from 'react-bootstrap';
import ListItems from '../../components/lots/ListItems';
import LotsFilter from '../../components/filters/LotsFilter';
import './index.css';
import { GET_LOTS_DATA } from '../../Queries';

const Lots = () => {
  const [lots, setLots] = useState([]);
  // const [lot, setLot] = useState([]);
  const [checkedMaxMin] = useState(new Map());
  const [checkedMax, setCheckedMax] = useState(false);
  const [checkedMin, setCheckedMin] = useState(false);

  const [arrayFilter, setArrayFilter] = useState({
    libelle_statut: [],
    lieu_localite: [],
    montant: [],
  });

  const filteredLot = () => {
    Object.keys(arrayFilter).map((key) => {
      console.log(arrayFilter[key].length, arrayFilter[key]);
    });
  };

  // console.log(arrayFilter['montant'], arrayFilter['montant1']);

  filteredLot();

  const { loading, error } = useQuery(GET_LOTS_DATA, {
    onCompleted: (data) => setLots(data.lots),
  });

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  const maxMinChangeHandler = (e) => {
    const { value, name, checked } = e.currentTarget;

    if (name === 'max') {
      setCheckedMax(checked);
      if (checked) {
        checkedMaxMin.set(name, value);
      } else {
        checkedMaxMin.delete(name);
      }
    } else {
      setCheckedMin(checked);
      if (checked) {
        checkedMaxMin.set(name, value);
      } else {
        checkedMaxMin.delete(name);
      }
    }

    setArrayFilter({
      ...arrayFilter,
      montant: [checkedMaxMin.get('min'), checkedMaxMin.get('max')],
    });
  };

  return (
    <div className="container custom-container">
      <Row className="">
        <Col lg={4}>
          <LotsFilter
            lots={lots}
            onChangeMinMax={maxMinChangeHandler}
            checkedMaxMin={checkedMaxMin}
            checkedMax={checkedMax}
          />
        </Col>
        <Col lg={8}>
          <Row gap={4}>
            {lots.map((lot, idx) => (
              <Col lg={6} key={lot.id}>
                <ListItems lot={lot} />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Lots;
