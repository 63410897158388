import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";
import { setShowToChangeCurrency$ } from "../utils/rxjs";

export default function ModalAlert() {
  const [show, setShow] = useState(false);
  const [showToChangeCurrency, setShowToChangeCurrency] = useState(false);
  const cookies = new Cookies();
  const { t } = useTranslation('alert');

  useEffect(() => {
    if (cookies.get("isVisited") !== "true") {
      setShow(true);
    }
    setShowToChangeCurrency$.subscribe((value) => { setShowToChangeCurrency(value) } );
  }, []);

  const handleClose = () => {
    const current = new Date();
    const nextYear = new Date();
    nextYear.setFullYear(current.getFullYear() + 1);

    cookies.set("isVisited", true, { path: "/", expires: nextYear });
    setShow(false);
  };

const handleCloseToChangeCurrency = () => {
    setShowToChangeCurrency(false);
    // setShowToChangeCurrency$.next(false);
  };



  return (
    <>
      {/* Modal alert to the first visit to page */}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        className="ciustom-modal-alert"
      >
        <Modal.Body className="p-4">
          <h5 className="text-center font-base {text}-black fs-4 fw-bold mt-3">
            {t("Important Information")}
          </h5>
          <p className="custom-text-alter text-center py-3">{t("body")}</p>
          <div className="d-flex justify-content-center">
            <Button
              className="btn-base-primary mb-4"
              variant="primary"
              onClick={handleClose}
            >
              {t("yes I understood")}
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Modal alert to change currency */}
      <Modal
        show={showToChangeCurrency}
        onHide={handleCloseToChangeCurrency}
        backdrop="static"
        keyboard={false}
        centered
        className="ciustom-modal-alert"
      >
        <Modal.Body className="p-4">
          <h5 className="text-center font-base {text}-black fs-4 fw-bold mt-3">
            {t("Warning")}
          </h5>
          <p className="custom-text-alter text-center py-3">{t("Warning: The displayed currency")}</p>
          <div className="d-flex justify-content-center">
            <Button
              className="btn-base-primary mb-4"
              variant="primary"
              onClick={handleCloseToChangeCurrency}
            >
              {t("yes I understood")}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
