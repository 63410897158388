import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const ProtectedRoute = (props) => {

    const navigate = useNavigate();
    const { state } = useLocation()
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const { auth } = useAuth();

    const checkUserToken = () => {

        if (!auth || auth === 'undefined') {
            setIsLoggedIn(false);
            return navigate('/login', {
                state: {
                pathname: "/client/investment",
                project: state?.project
            }});
        }
        setIsLoggedIn(true);
    }

    useEffect(() => {
        checkUserToken();
    }, [isLoggedIn]);


    return (
        <React.Fragment>
            {
                isLoggedIn ? props.children : null
            }
        </React.Fragment>
    );
}
export default ProtectedRoute;