import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { useNavigate, useLocation } from "react-router-dom";
import { Form, Button, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LOGIN_MUTATION } from "../Queries";
import Cookies from "universal-cookie";
import "./account.css";
import useAuth from "../hooks/useAuth";
import APP_ENV from "../config/Env";
import { useTranslation } from "react-i18next";

const LoginFailedMessageWindow = (props) => {
    return (
        <>
            {props.message === "invalid_grant" && (
                <span className="text-danger my-5 error-message">
                    {props.content}
                </span>
            )}
        </>
    );
};

const Login = () => {
    const { t } = useTranslation("login");
    const { state } = useLocation();
    const navigate = useNavigate();
    const cookies = new Cookies();
    const { setAuth } = useAuth();
    const [formState, setFormState] = useState({
        username: "",
        password: "",
    });
    
    const [errors, setErrors] = useState({
        username: false,
        password: false,
    });

    const [login, { loading, error, reset }] = useMutation(LOGIN_MUTATION, {
        variables: {
            username: formState.username,
            password: formState.password,
        },
        onCompleted: (data) => {

            //For persisting auth on BO
            const current = new Date();
            const nextYear = new Date();
            nextYear.setFullYear(current.getFullYear() + 1);

            cookies.set("currentId", data.login?.user?.id, {
                domain: APP_ENV.domain,
                expires: nextYear,
            });

            cookies.set("authUser", data.login, {
                domain: APP_ENV.domain,
                expires: nextYear,
            });

            cookies.remove("logoutUser", { domain: APP_ENV.domain });

            setAuth(data.login);
           
            if (state?.project && state?.pathname.includes("/client/investment")) {
                navigate(`/client/investment/${state.project.code}`, { state: { project: state.project } });
            } else if (state?.pathname.includes("/simulation-calculator")) {
                navigate(`/simulation-calculator/${state.project.code}`, { state: { project: state.project } });
            } else if (state?.pathname.includes("/projets/")) {
                navigate(state?.pathname);
                
            } else {
                navigate("/");
            }
        },
    });


    const handleChanger = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;

        setFormState({
            ...formState,
            [name]: value,
        });
    };

    const validateForm = () => {
        const { username, password } = formState;
        let isValidForm = true;

        if (!username) {
            isValidForm = false;
            setErrors({ ...errors, username: true });
            setTimeout(() => {
                setErrors({ ...errors, username: false });
            }, 5000);
        }

        if (!password) {
            isValidForm = false;
            setErrors({ ...errors, password: true });
            setTimeout(() => {
                setErrors({ ...errors, password: false });
            }, 5000);
        }

        return isValidForm;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (validateForm()) {
            login();
        }
    };

    return (
        <section>
            <div className="container mt-n4">
                <h5 className="text-uppercase fw-bold font-base text-center text-blue">
                    {t('LOG IN TO REISE')}
                </h5>
                <p className="text-center py-1">
                    {t('Not have an account')}{" "}
                    <Link to="/register"> {t('Click here to create one')} </Link>
                </p>
                <div className="card mx-auto" style={{ maxWidth: "380px", border: 0 }}>
                    <div className="card-body">
                        <Form
                            className="form-register"
                            autoComplete="off"
                            onSubmit={handleSubmit}
                        >
                            <Form.Group className="mb-3" controlId="formGroupEmail">
                                <Form.Control
                                    type="email"
                                    name="username"
                                    onChange={handleChanger}
                                    placeholder={t('Enter email')}
                                />
                                {errors.username && (
                                    <span className="text-danger font-base fw-400">
                                        {t('field required')}
                                    </span>
                                )}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formGroupPassword">
                                <Form.Control
                                    type="password"
                                    name="password"
                                    onChange={handleChanger}
                                    placeholder={t('Password')}
                                    autoComplete="new-password"
                                />
                                {errors.password && (
                                    <span className="text-danger font-base fw-400">
                                        {t('field required')}
                                    </span>
                                )}
                            </Form.Group>
                            <Form.Group className="mb-5">
                                <a
                                    href={`${APP_ENV.baseURL}/password/reset`}
                                    className="nav-link float-right font-base fw-400 text-primary"
                                >
                                    {t('Forgot you password')}
                                </a>
                            </Form.Group>
                            <Form.Group>
                                {!loading ? (
                                    <Button type="submit" className="btn-base-primary btn-block">
                                        {t('Login')}
                                    </Button>
                                ) : (
                                    <Button
                                        variant="primary"
                                        disabled
                                        className="btn-base-primary btn-block"
                                    >
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        <span className="px"> {t('loding')} </span>
                                    </Button>
                                )}
                            </Form.Group>
                        </Form>
                        {error && (
                            <LoginFailedMessageWindow
                                message={error.message}
                                content={t('The credentials provided')}
                                onDismiss={() => reset()}
                            />
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Login;
