
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { Container, Col, ListGroup, Row, Button, Modal, Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
// import { CurrencyConverter } from '../../utils/utils';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { OPENFORINVESTMENT, VOTING, VERIFICATIONINPROGRESS } from "../../Global";
import useAuth from '../../hooks/useAuth';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_INTERESSER, GETINTERESSE_BY_PROJET_AND_USER } from '../../Queries';
import Loading from '../../components/loading';
import APP_ENV from '../../config/Env';
import Cookies from 'universal-cookie';
import { currency$ } from '../../utils/rxjs';



function Simulator() {

    const cookies = new Cookies();
    const { auth } = useAuth();
    const { t } = useTranslation('sumilation-calculator')
    const { state } = useLocation();
    const navigate = useNavigate();
    const project = state?.project;

    const [currentValue, setValue] = useState(project.invest_min)
    const [intendToInvest, setIntendToInvest] = useState(project.invest_min)
    const [min] = useState(project.invest_min)
    const [max] = useState(project.cout)
    const [step] = useState(project.saut_investissement || 1000000)
    const STATUS = project.code_statut;
    const [showBtn, setShowBtn] = useState(false)
    const [show, setShow] = useState(false);
    const [createProspectSuccess, setCreateSuccess] = useState(false);
    const range = useRef(null);
    const refIntendToInvest = useRef(null);
    const [devise, setDevise] = useState("XOF");
    const [rate, setRate] = useState(null);
    

    const { loading } = useQuery(GETINTERESSE_BY_PROJET_AND_USER, {
        variables: {
            projet_id: parseInt(project.id),
            user_id: parseInt(auth?.user.id),
        },
        onCompleted: (data) => {
            if (data.interesseByProjetAndUser) {
                setShowBtn(true)
            }
        },
        fetchPolicy: "no-cache",
    });

    const [addInteress, { loading: loadingCreation }] = useMutation(CREATE_INTERESSER);

    const handleAddInteresse = () => {
        addInteress({
            variables: {
                projet_id: parseInt(project.id),
                user_id: parseInt(auth?.user.id),
                montant: intendToInvest
            },
            onCompleted: (data) => {
                setShow(false)
                setCreateSuccess(true)
                setShowBtn(true)
            },
        });
    };

    const getPercent = useCallback(
        (value) => Math.round(((value - min) / (max - min)) * 100),
        [min, max]
    );

    function toDecimal(percent) {
        return parseFloat(percent) / 100;
    }

    const returnOnInvestment = () => {

        return Math.round(toDecimal(project?.roi) * currentValue)
    }


    useEffect(() => {
        const minPercent = getPercent(currentValue);
        const minPercentIntendToInvest = getPercent(intendToInvest);

        if (range.current) {
            range.current.style.left = `${minPercent > 27 ? minPercent - 25 : minPercent}%`;
        }

        if (refIntendToInvest.current) {
            refIntendToInvest.current.style.left = `${minPercentIntendToInvest > 27 ? minPercentIntendToInvest - 25 : minPercentIntendToInvest}%`;
        }
    }, [currentValue, intendToInvest, getPercent]);


    // use effect to get the currency

    useEffect(() => {
        currency$.subscribe((currency) => {
          setDevise(currency?.code || "XOF");
          setRate(currency?.originalLibelle);
        });
      }, []);

      
    const onInput = (inputs) => {
        setValue(inputs[1])
    }

    const handleShowModal = () => {

        if (auth && !auth.user.email_verified_at) {
            const current = new Date();
            const nextYear = new Date();
            nextYear.setFullYear(current.getFullYear() + 1);
            cookies.set("fromWebsite", true, {
                domain: APP_ENV.domain,
                expires: nextYear,
            });

             window.location.href = APP_ENV.baseURL;

        } else if (auth) {
            setShow(true)
        } else {
            navigate('/login', {
                 state: {
                    pathname: "/simulation-calculator",
                    project: project
                }}
            );
        }
    }

    const CurrencyConverter = (money,) => {

        if (money) {
          if (!Number.isInteger(money)) {
            money = parseInt(money.replaceAll(" ", ""));
          }
      
          if (devise === "EUR" && rate) {
            return new Intl.NumberFormat("fr-FR", {
              style: "currency",
              currency: "EUR",
            }).format(money / rate);
          } else {
            return new Intl.NumberFormat("fr-FR", {
              style: "currency",
              currency: "XOF",
            }).format(money);
          }
        }
      
        return new Intl.NumberFormat("fr-FR", {
          style: "currency",
          currency: devise,
        }).format(0);
      };

      
    if (loading) return <Loading />
    return (
        <>
            {!createProspectSuccess ?
                <Container>
                    <Row className="d-flex justify-content-center">
                        <Col lg={10}>
                            <div className="investment-offer-details mb-5">
                                <h3 className="font-heading text-md-center text-blue mt-4 mb-5">
                                    {t('Estimate my return on investment')}
                                </h3>
                                <div className="mx-auto">
                                    <Row>
                                        <Col md={6}>
                                            <ListGroup variant="flush">
                                                <ListGroup.Item className="invst-project-details">
                                                    {t('Project name')} : <span className="fw-bold">{project?.details?.nom}</span>
                                                </ListGroup.Item>
                                                <ListGroup.Item className="invst-project-details">
                                                    {t('Area')} : <span className="fw-bold text-lowercase"> {project?.superficie} {project.libelle_unite}</span>
                                                </ListGroup.Item>
                                                <ListGroup.Item className="invst-project-details">
                                                    {t('Minimum investment')} : <span className="fw-bold"> {CurrencyConverter(project?.invest_min)} </span>
                                                </ListGroup.Item>
                                                <ListGroup.Item className="invst-project-details">
                                                    {t('Return on Investment')} : <span className="fw-bold"> {project?.roi}% </span>
                                                </ListGroup.Item>
                                            </ListGroup>
                                        </Col>
                                        <Col md={6}>
                                            <ListGroup variant="flush">
                                                <ListGroup.Item className="invst-project-details">
                                                    {t('Project type')} : <span className="fw-bold"> {project?.libelle_type} </span>
                                                </ListGroup.Item>
                                                <ListGroup.Item className="invst-project-details">
                                                    {t('Locality')} : <span className="fw-bold"> {project?.localite} </span>
                                                </ListGroup.Item>
                                                <ListGroup.Item className="invst-project-details">
                                                    {t('Investment target')} : <span className="fw-bold"> {CurrencyConverter(project?.cout)} </span>
                                                </ListGroup.Item>
                                                <ListGroup.Item className="invst-project-details">
                                                    {t('Estimated duration')} : <span className="fw-bold"> {project?.duree} {t(project.libelle_typeduree)} </span>
                                                </ListGroup.Item>
                                            </ListGroup>
                                        </Col>
                                    </Row>
                                </div>
                                <hr className="divider mb-4" />
                                <div>
                                    <Row className="g-5">
                                        <Col md={7}>
                                            <h5 className="font-base text-black fs-4 fw-bold">
                                                {t("Simulator")}
                                            </h5>
                                            <h6 className="font-base mb-5">
                                                {t('Swipe to choose an amount')}
                                            </h6>
                                            <div className="custom-ranger-slider">
                                                <p className="current-value-checked py-1" ref={range}>
                                                    {CurrencyConverter(currentValue)}
                                                </p>
                                                <RangeSlider
                                                    min={min}
                                                    max={max}
                                                    value={[min, currentValue]}
                                                    className="single-thumb"
                                                    thumbsDisabled={[true, false]}
                                                    rangeSlideDisabled={true}
                                                    step={step}
                                                    onInput={inputs => onInput(inputs)}
                                                />
                                                <div className="slider__left-value px-3">
                                                    min:  {CurrencyConverter(project.invest_min)}
                                                </div>
                                                <div className="slider__right-value px-4">
                                                    max:  {CurrencyConverter(project.cout)}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col className="ml-md-4">
                                            <h6 className="mt-5 mt-md-0 py-2 pl-md-5 ml-2 font-base fw-400"> {t('Invest')} : </h6>
                                            <h3 className="text-black font-base fs-3 fw-bold"> {CurrencyConverter(currentValue)} </h3>

                                            <h6 className="mt-md-4 py-2 pl-md-5 font-base fw-400"> {t('Will earn you')} : </h6>
                                            <h3 className="text-black font-base fs-3 fw-bold"> {CurrencyConverter(returnOnInvestment())} </h3>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <div className="d-flex justify-content-start my-5">
                                {STATUS === OPENFORINVESTMENT && (
                                    <Link
                                        className="base-navlink mt-5 text-uppercase"
                                        to={`/client/investment/${project.code}`}
                                        state={{ project: project }}
                                    >
                                        {t('I want to invest')}
                                    </Link>
                                )}

                                {(STATUS === VOTING || STATUS === VERIFICATIONINPROGRESS) && (
                                    <>
                                        {!showBtn ?
                                            <Button
                                                variant="primary"
                                                className="btn-base-primary mt-5"
                                                type="submit"
                                                onClick={handleShowModal}
                                            >
                                                {t('I am interested in this project')}
                                            </Button>
                                            :
                                            <Link
                                                className="base-navlink mt-5 text-uppercase"
                                                to={`/projets/${project.code}`}>
                                                {t('RETURN')}
                                            </Link>
                                        }
                                    </>
                                )}
                            </div>
                        </Col>
                    </Row>

                    {/* modal interset */}
                    <Modal
                        size="lg"
                        show={show}
                        onHide={setShow}
                        centered
                        className="custom-modal-alert"
                    >
                        <Modal.Body className="p-4">
                            <h5 className="text-center font-base {text}-black fs-4 fw-bold mt-3">
                                {t("How much do you intend to invest")}
                            </h5>
                            <div className="py-5 custom-ranger-slider">
                                <p className="current-value-checked" ref={refIntendToInvest}>
                                    {CurrencyConverter(intendToInvest)}
                                </p>
                                <RangeSlider
                                    min={min}
                                    max={max}
                                    className="single-thumb"
                                    thumbsDisabled={[true, false]}
                                    rangeSlideDisabled={true}
                                    step={1000000}
                                    onInput={values => setIntendToInvest(values[1])}
                                />
                                <div className="slider__left-value px-3"> {CurrencyConverter(min)} </div>
                                <div className="slider__right-value px-4"> {CurrencyConverter(max)} </div>
                            </div>

                            <div className="d-flex justify-content-center mt-5">

                                {!loadingCreation ? (
                                    <Button
                                        className="custom-btn-invest btn-base-primary mb-4"
                                        variant="primary"
                                        onClick={handleAddInteresse}
                                    >
                                        {t("CONFIRME")}
                                    </Button>
                                ) : (
                                    <Button
                                        variant="primary"
                                        disabled
                                        className="custom-btn-invest btn-base-primary mb-4"
                                    >
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        <span className="px"> {t("loading")} </span>
                                    </Button>
                                )}
                            </div>
                        </Modal.Body>
                    </Modal>
                </Container>
                :
                <CreateProspectSuccess project={project} />
            }

        </>
    )
}

export default Simulator


function CreateProspectSuccess({ project }) {

    const { t } = useTranslation('sumilation-calculator')

    return (
        <Row className="container d-flex justify-content-center my-5">
            <Col lg={8}>
                <h5 className="text-uppercase fw-bold font-base text-center text-blue">
                    {t('Registration of your interest')}
                </h5>

                <p className="text-center py-4 text-notification">
                    {t('Your interest has been registered')}
                </p>
                <div className="d-flex justify-content-center py-4">
                    <Link to={`/projets/${project.code}`} className="notifation btn-return">
                        {t('RETURN TO PREVIOUS PAGE')}
                    </Link>
                </div>
            </Col>
        </Row>
    )
}

