import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from 'react-router-dom';

import "./fab.css";
import FloatingWhatsApp from 'react-floating-whatsapp';
import { useTranslation } from 'react-i18next';
import callCenterOperator from '../../images/call-center-operator.svg'
import invest from '../../images/investir.png'
import { FaCalculator } from 'react-icons/fa';
import { BiLike } from "react-icons/bi";
import { staticAsset } from "../../utils/utils";
import { OPENFORINVESTMENT, VOTING, VERIFICATIONINPROGRESS } from "../../Global";
import { interested$, modaleHandler } from "../../utils/rxjs";
import Cookies from "universal-cookie";
import APP_ENV from "../../config/Env";

const Fab = (props) => {

    const { t } = useTranslation("chatWhatsapp");
    let navigate = useNavigate();
    const { pathname } = useLocation();
    const [show, setShow] = useState(false)
    const { projet, user } = props;
    const STATUS = projet?.code_statut;
    const cookies = new Cookies();

    const customTextChat = () => {
        return <>
            {t('Hello')} <br />
            {t('message')}
        </>
    }

    useEffect(() => {
        interested$.subscribe(newValue => setShow(newValue))
        const whatsappButton = document.querySelector('.styles-module_whatsappButton__IWx9V');
        const textAdded = document.querySelector('.add-floting-text-in-whatsapp-btn');

        // Create a <a> element
        if (whatsappButton && !textAdded) {
            const elem = document.createElement("a");

            // create a class
            elem.setAttribute('class', 'add-floting-text-in-whatsapp-btn')

            // Create a new text node
            const text = document.createTextNode(t('I want to speak to an agent'));

            // Append text node to <a> node
            elem.appendChild(text);

            // Finally, append <li> to <div> node
            whatsappButton.appendChild(elem);
        }

    })

    const disbaleLink = {
        color: 'currentColor',
        cursor: 'not-allowed',
        opacity: 0.5,
        textDecoration: 'none'
    }

    const handleClick = () => {

       if (user && !user.email_verified_at) {
            const current = new Date();
            const nextYear = new Date();
            nextYear.setFullYear(current.getFullYear() + 1);
            cookies.set("fromWebsite", true, {
                domain: APP_ENV.domain,
                expires: nextYear,
            });

             window.location.href = APP_ENV.baseURL;

        } else if (user) {
            modaleHandler()
        } else {
            navigate("/login", { state: { pathname: pathname } });
        }
    }

    return (
        <div className="fab-container">
            <div className="fab">
                <div className="can-i-help-you"> {t('help')} </div>
                <div className="fab-btn-call-center">
                    <img className="custom-call-operator" src={callCenterOperator} />
                </div>
            </div>
            <ul className="fab-options">
                {STATUS === OPENFORINVESTMENT && (
                    <Link
                        to={`/client/investment/${projet.code}`}
                        state={{
                            pathname: "/client/investment",
                            project: projet
                        }}>
                        <li>
                            <span className="fab-label">{t('I want to invest')}</span>
                            <div className="fab-icon-holder">
                                <img className="invstment" src={invest} />
                            </div>
                        </li>
                    </Link>
                )}
                {(STATUS === VOTING || STATUS === VERIFICATIONINPROGRESS) && (
                    <a onClick={handleClick} style={show ? disbaleLink : {}}>
                        <li>
                            <span className="fab-label">{t('I am interested in this project')}</span>
                            <div className="fab-icon-holder">
                                <BiLike className="calculate-roi" color='#FFF' size={25} />
                            </div>
                        </li>
                    </a>
                )}
                <Link
                    to={`/simulation-calculator/${projet.code}`}
                    state={{ project: projet }}
                >
                    <li>
                        <span className="fab-label">{t('Calculate my return on investment')}</span>
                        <div className="fab-icon-holder">
                            <FaCalculator className="calculate-roi" color='#FFF' size={25} />
                        </div>
                    </li>
                </Link>

                <FloatingWhatsApp
                    avatar={staticAsset("logo-blanc.png")}
                    phoneNumber="221770952155"
                    accountName="REISE"
                    chatMessage={customTextChat()}
                    statusMessage=""
                    className='custom-z-index'
                    height={327}
                    placeholder={t('Type a message')}
                // allowClickAway={true}
                />
            </ul>
        </div>
    );
};

export default Fab;
