
const APP_ENV = new (function () {
    this.domain = process.env.REACT_APP_DOMAIN;
    this.baseURL = process.env.REACT_APP_BASE_URL;
    this.wp_baseURL = process.env.REACT_APP_WP_BASE_URL;
    this.baseURL_graphql = `${process.env.REACT_APP_BASE_URL}/graphql`;
    this.google_maps_api_key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    this.asset_url = process.env.REACT_APP_ASSETS_URL;
    this.asset_static_url = process.env.REACT_APP_ASSETS_STATIC_URL;
  })();
  
  export default APP_ENV;