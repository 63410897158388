import { useMutation, useQuery } from '@apollo/client';
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { Button, Col, ListGroup, Form, Row, Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { CREATE_INVESTMENT, GETINTERESSE_BY_PROJET_AND_USER } from '../../Queries';
import { CurrencyConverter } from '../../utils/utils';

import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';

function InvestmentOfferDetails(props) {

    const { project, user } = props;
    const { t } = useTranslation('invest')
    const [currentValue, setValue] = useState(project.invest_min)
    const [min] = useState(project.invest_min)
    const [max] = useState(project.cout - project.montant_invest)
    const [step] = useState(project.saut_investissement || 1000000)
    const [validated, setValidated] = useState(false);
    const range = useRef(null);
    const [formState, setFormState] = useState({
        montant: currentValue,
        statut: "Réservation",
        type: 'Réservation',
        user_id: parseInt(user.id),
        projet_id: parseInt(project.id),
        pris_connaissance_CGU: false,
        certifie_fonds_investissement: false,
        accepter_les_risques: false,
        elaboration_previsionnel_roi_duree: false,
    });


    const getPercent = useCallback(
        (value) => Math.round(((value - min) / (max - min)) * 100),
        [min, max]
    );

    function toDecimal(percent) {
        return parseFloat(percent) / 100;
    }

    const returnOnInvestment = () => {

        return Math.round(toDecimal(project?.roi) * currentValue)
    }

    useEffect(() => {
        const minPercent = getPercent(currentValue);
        if (range.current) {
            range.current.style.left = `${minPercent > 27 ? minPercent - 25 : minPercent}%`;
        }
    }, [currentValue, getPercent]);


    const handleChange = (e) => {
        const target = e.target;
        const value = target.checked
        const name = target.name;
        setFormState({
            ...formState,
            [name]: value
        })
    }

    const onInput = (inputs) => {
        setValue(inputs[1])
        setFormState({
            ...formState,
            montant: inputs.pop()
        })
    }

    useQuery(GETINTERESSE_BY_PROJET_AND_USER, {
        variables: {
            projet_id: parseInt(project.id),
            user_id: parseInt(user?.id),
        },
        onCompleted: (data) => {
            if (data.interesseByProjetAndUser && data.interesseByProjetAndUser.montant < max) {
                console.log(data.interesseByProjetAndUser.montant, max)
                setValue(data.interesseByProjetAndUser.montant)
                setFormState({
                    ...formState,
                    montant: data.interesseByProjetAndUser.montant
                })
            }
        },
    });

    const [createInvestment, { loading, error }] = useMutation(CREATE_INVESTMENT);
    
    const previous = (e) => {
        e.preventDefault()
        props.back()
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            createInvestment({
                variables: formState,
                onCompleted: (data) => props.nextStep()
            });
        }
        setValidated(true);
    };


    return (
        <div className="investment-offer-details">
            <h3 className="font-heading text-md-center text-blue py-4 mt-4">
                {t('Investment offer details')}
            </h3>
            <div className="mx-auto mb-5">
                <Row>
                    <Col md={6}>
                        <ListGroup variant="flush">
                            <ListGroup.Item className="invst-project-details">
                                {t('Project name')} : <span className="fw-bold">{project?.details?.nom}</span>
                            </ListGroup.Item>
                            <ListGroup.Item className="invst-project-details">
                                {t('Area')} : <span className="fw-bold text-lowercase"> {project?.superficie} {project.libelle_unite}</span>
                            </ListGroup.Item>
                            <ListGroup.Item className="invst-project-details">
                                {t('Minimum investment')} : <span className="fw-bold"> {CurrencyConverter(project?.invest_min)} </span>
                            </ListGroup.Item>
                            <ListGroup.Item className="invst-project-details">
                                {t('Return on Investment')} : <span className="fw-bold"> {project?.roi}% </span>
                            </ListGroup.Item>
                        </ListGroup>
                    </Col>
                    <Col md={6}>
                        <ListGroup variant="flush">
                            <ListGroup.Item className="invst-project-details">
                                {t('Project type')} : <span className="fw-bold"> {project?.libelle_type} </span>
                            </ListGroup.Item>
                            <ListGroup.Item className="invst-project-details">
                                {t('Locality')} : <span className="fw-bold"> {project?.localite} </span>
                            </ListGroup.Item>
                            <ListGroup.Item className="invst-project-details">
                                {t('Investment target')} : <span className="fw-bold"> {CurrencyConverter(project?.cout)} </span>
                            </ListGroup.Item>
                            <ListGroup.Item className="invst-project-details">
                                {t('Estimated duration')} : <span className="fw-bold"> {project?.duree} {t('Months')} </span>
                            </ListGroup.Item>
                        </ListGroup>
                    </Col>
                </Row>
            </div>
            <hr className="divider mb-4" />
            <div>
                <Row className="g-5">
                    <Col md={7}>
                        <h5 className="font-base text-black fs-4 fw-bold">
                            {t("How much do you want to invest")}
                        </h5>
                        <h6 className="font-base mb-5">
                            {t('Swipe to choose an amount')}
                        </h6>
                        <div className="custom-ranger-slider">
                            <p className="current-value-checked py-1" ref={range}> {CurrencyConverter(currentValue)} </p>
                            <RangeSlider
                                min={min}
                                max={max}
                                value={[min, currentValue]}
                                className="single-thumb"
                                thumbsDisabled={[true, false]}
                                rangeSlideDisabled={true}
                                step={step}
                                onInput={inputs => onInput(inputs)}
                            />
                            <div className="slider__left-value px-3"> {CurrencyConverter(min)} </div>
                            <div className="slider__right-value px-4"> {CurrencyConverter(max)} </div>
                        </div>
                    </Col>
                    <Col className="ml-md-4">
                        <h6 className="mt-5 mt-md-0 py-2 pl-md-5 ml-3 font-base fw-400"> {t('Invest')} :</h6>
                        <h3 className="text-black font-base fs-3 fw-bold"> {CurrencyConverter(currentValue)} </h3>

                        <h6 className="mt-md-4 py-2 pl-md-5 font-base fw-400"> {t('You will earn')} :</h6>
                        <h3 className="text-black font-base fs-3 fw-bold"> {CurrencyConverter(returnOnInvestment())} </h3>
                    </Col>
                </Row>

            </div>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div className="form-check-condition">
                    <Form.Group className="mb-3" controlId="formInfosInvestCheckbox">
                        <Form.Check
                            type="checkbox"
                            name="pris_connaissance_CGU"
                            checked={formState.pris_connaissance_CGU}
                            onChange={handleChange}
                            label={t('I declare that I have read the general conditions of use')}
                            required
                            feedback={t('You must accept this condition before submitting')}
                            feedbackType="invalid"
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formCertifieFondsInvestissementCheckbox">
                        <Form.Check
                            type="checkbox"
                            name="certifie_fonds_investissement"
                            checked={formState.certifie_fonds_investissement}
                            onChange={handleChange}
                            label={t('certification of investment funds')}
                            required
                            feedback={t('You must accept this condition before submitting')}
                            feedbackType="invalid"
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formAccepterRisquesCheckbox">
                        <Form.Check
                            type="checkbox"
                            name="accepter_les_risques"
                            checked={formState.accepter_les_risques}
                            onChange={handleChange}
                            label={t('recognize accept the risk of loss')}
                            required
                            feedback={t('You must accept this condition before submitting')}
                            feedbackType="invalid"
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formCompredsElaborationCheckbox">
                        <Form.Check
                            type="checkbox"
                            name="elaboration_previsionnel_roi_duree"
                            checked={formState.elaboration_previsionnel_roi_duree}
                            onChange={handleChange}
                            label={t('understand elaboration forecast king duration')}
                            required
                            feedback={t('You must accept this condition before submitting')}
                            feedbackType="invalid"
                        />
                    </Form.Group>
                </div>
                <div className="d-flex justify-content-between py-4 mt-5">
                    <Button
                        variant="primary"
                        className="btn-base-primary text-uppercase"
                        onClick={(e) => previous(e)}
                    >
                        {t('PREVIOUS')}
                    </Button>
                    {!loading ?
                        <Button
                            variant="primary"
                            className="btn-base-primary"
                            type="submit"
                        // onClick={(e) => saveAndContinue(e)}
                        >
                            {t('CONFIRME')}
                        </Button>
                        :
                        <Button variant="primary" disabled className="btn-base-primary">
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            <span className="px">
                                {t('confirmation in progress')}
                            </span>
                        </Button>
                    }

                </div>
            </Form>
        </div>
    )
}

export default InvestmentOfferDetails
