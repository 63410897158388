import ListItems from './ListItems.js';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import EmptyMessage from './EmptyMessage.js';
import { useTranslation } from 'react-i18next';

export default function SimilarProjects ({similarProjects}) {
  const { t } = useTranslation('project')
    const items = similarProjects?.length < 3 ? similarProjects?.length : 3
    const settings = {
      dots: false,
      infinite: false,
      autoplay: true,
      speed: 500,
      slidesToShow: items,
      slidesToScroll: items,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    
    return (
        <div className="col-lg-12">
            <div className="similiar__item">
                <h6 className="detail-heading">
                    {t('Other projects that might interest you')}
                </h6>
                <div className=" mb-5">
                    {similarProjects?.length ? 
                        <Slider {...settings}>
                            {similarProjects?.map((projet) =>(
                                <div className='item'  key={projet.id}>
                                    <ListItems width='21rem' projet={projet} />
                                </div>
                            ))}
                        </Slider>
                    :
                    <EmptyMessage />
                    }
                    
                </div>
            </div>
        </div> 
    )
}