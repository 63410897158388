import Banners from "./Banners";
import Menu from "./menu/Menu";
import './Layout.css'

export default function Header () {
    return (
        <header>
            <Banners />
            <Menu />
        </header>
    )
}



  