import Card from 'react-bootstrap/Card';
import PropTypes from 'prop-types';
import { Link} from 'react-router-dom';
import {Currency} from '../../utils/utils'


import ListGroup from 'react-bootstrap/ListGroup';
import { FaMapMarkerAlt } from 'react-icons/fa';
// import './slider.css'

import immo1 from '../../images/immo.png';

export default function ListItems({ width, lot }) {
  return (
    <Card style={{ width: width }} className="mb-4">
      <Card.Img className="image-projet" variant="top" src={immo1} />
      <Card.ImgOverlay>
        <Card.Text className="bg-primary text-white text-uppercase custom-subtile">
          En vote
        </Card.Text>
      </Card.ImgOverlay>
      <Card.Body>
        <ListGroup variant="flush" className=" ml-n2">
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between custom-list-group-item"
          >
            <div className="fw-500 fw-bold">{lot.titre}</div>
          </ListGroup.Item>
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between custom-list-group-item"
          >
            <div>
              {' '}
              <FaMapMarkerAlt size={15} /> &nbsp; Dakar
            </div>
            <small>Délibération</small>
          </ListGroup.Item>
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between custom-list-group-item"
          >
            <div className="fw-500">
              Parcelle: <br />
              {lot.terrains?.map((item, i) => (
                <span className="pr-2" key={i}>
                  {item.superficie} m <sup>2</sup>
                  <span className="parcelle-num">({item.num_plle})</span>
                </span>
              ))}
            </div>
          </ListGroup.Item>
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between custom-list-group-item text-truncate"
          >
            <div className="text-truncate fw-bold">
              A partir de {Currency(lot.montant)}
            </div>
          </ListGroup.Item>
        </ListGroup>
      </Card.Body>
      <Card.Footer className="position-relative custom-card-footer">
        <Link
          to={`/lots/${lot.id}`}
          className="text-decoration-none stretched-link text-center text-white"
        >
          DÉTAILS DU PROJET
        </Link>
      </Card.Footer>
    </Card>
  );
}

ListItems.propTypes = {
  projet: PropTypes.object,
};

ListItems.defaultProps = {
  projet: {},
};
