import { useTranslation } from "react-i18next";
import { ACHAT_REVENTE, VEFA, LOCATIF, LOTISSEMENT } from '../../Global'
import { CurrencyConverter, asset } from "../../utils/utils";
import EmptyMessage from "../EmptyMessage";
import { BsFillFileEarmarkFill } from "react-icons/bs";


export default function Investment({ projet }) {
  const { t } = useTranslation("project");
  return (
    <>
      {/* Données financières */}
      <div className="single__detail-features">
        <h5 className="text-capitalize detail-heading"> {t("Financials")} </h5>
        <div className="row property__detail-info">
          <ul className="col-lg-6 property__detail-info-list list-unstyled text-nowrap">
            <li>
              <b> {t("Investment target")} :</b> {CurrencyConverter(projet.cout)}
            </li>
            <li>
              <b>{t("Return on Investment")} :</b>  {projet.roi} %
            </li>
            <li>
              <b> {t("Minimum investment")}:</b> {CurrencyConverter(projet.invest_min)}
            </li>
          </ul>
          <ul className="col-lg-6 property__detail-info-list list-unstyled text-nowrap">
            <li>
              {projet.code_type === LOTISSEMENT ? (
                <b>{t("Average sale price per m2")} :</b>
              ) : projet.code_type === VEFA ? (
                <b> {t("Average price per m2 on delivery")} :</b>
              ) : (
                <b> {t("Resale value")} :</b>
              )}

              {CurrencyConverter(projet.prix_m2_loti) || t("Info not available")}
            </li>
            {projet.code_type === LOCATIF && (
              <>
                <li>
                  <b> {t("Net annual rental income")} :</b>  {CurrencyConverter(projet.apport_locatif_mens) || t("Info not available")}
                </li>
              </>
            )}
            <li>
              <b> {t("Investment duration")} :</b>  {projet.duree} {t(projet.libelle_typeduree)}
            </li>
          </ul>
        </div>
      </div>

      {/* Documents */}
      <div className="single__detail-features">
        <h5 className="text-capitalize detail-heading"> Documents </h5>
        <div className="property__detail-info">
          <div className="row">
            {projet.documents.length ? (
              projet?.documents.map((docs) => (
                <div
                  key={docs.id}
                  className="col-md-6 col-lg-6 mt-4 documents-details-projet"
                >
                  <a
                    href={asset(docs.location)}
                    className="py-2 text-decoration-none"
                    target="_blank"
                    rel="noreferrer"
                    download
                  >
                    <BsFillFileEarmarkFill size={18} />
                    <span className="pt-1 pl-1">
                      {" "}
                      {docs.file_name.split(".")[0]}{" "}
                    </span>
                  </a>
                </div>
              ))
            ) : (
              <EmptyMessage />
            )}
          </div>
        </div>
      </div>

      <div className="single__detail-desc">
        <h6 className="text-capitalize detail-heading">
          {t('Investment Highlights')}
        </h6>
        {projet.details?.raisoninvestir ?
          (<div>
            <p dangerouslySetInnerHTML={{ __html: projet.details?.raisoninvestir }}></p>
          </div>) :
          <EmptyMessage message={t('no available information')} />
        }
      </div>

      {/* Garanties et sécurités */}

      <div className="single__detail-desc">
        <h6 className="text-capitalize detail-heading">{t("Guarantees And Security")}</h6>
        {projet.details?.garanties ? (
          <div>
            <p
              dangerouslySetInnerHTML={{ __html: projet.details?.garanties }}
            ></p>
          </div>
        ) : (
          <EmptyMessage />
        )}
      </div>
    </>
  );
}
