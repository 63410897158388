// RxJS v6+
import { BehaviorSubject } from 'rxjs';


export const subject$ = new BehaviorSubject(false);

export const interested$ = new BehaviorSubject(false);

export const currency$ = new BehaviorSubject({});

export const setShowToChangeCurrency$ = new BehaviorSubject(false);

export const modaleHandler = () => {
    subject$.next(true)
}

export const handleAlterToChangeCurrency = () => {
    setShowToChangeCurrency$.next(true)
}