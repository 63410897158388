import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import {
  CREATE_INTERESSER,
  DELETE_INTERESSE,
  GETINTERESSE_BY_PROJET_AND_USER,
} from "../../Queries";
import "./filter.css";

import { Form, Button, Dropdown, Toast } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { BsWhatsapp } from "react-icons/bs";
import { ImWhatsapp } from "react-icons/im";
import { FaShare } from "react-icons/fa";
import { SimpleShareButtons } from "react-simple-share";

import MultiRangeSlider from "../multiRangeSlider/multiRangeSlider";
import { OPENFORINVESTMENT, VOTING, VERIFICATIONINPROGRESS } from "../../Global";
import Select from "react-select";
import ButtonInterested from "./ButtonInterested";
import ButtonNotintrested from "./ButtonNotintrested";
import { useTranslation } from "react-i18next";
import ModalInvest from "./ModaleInvest";
import { subject$, interested$ } from "../../utils/rxjs";
import APP_ENV from "../../config/Env";
import Cookies from "universal-cookie";

export default function ProjectFilter(props) {

  const { t } = useTranslation("project");

  const {
    statutes,
    types,
    lieux,
    minInvestMin,
    maxInvestMin,
    minRoi,
    maxRoi,
    investMinStep,
    roiStep,
    investmentPack,
    statusProject,
    user,
    arrayFilter,
    projects,
    project,
    invest_min,
    projectCout
  } = props;
  const cookies = new Cookies();
  const [show, setShow] = useState(false);
  let navigate = useNavigate();
  const { pathname } = useLocation();
  const [showInterest, setShowInterest] = useState(false);
  const [showUnInterest, setShowUnInterest] = useState(false);
  const [showBtnInteresse, setshowBtnInteresse] = useState(false);
  const [interestedId, setInterestedId] = useState(null);
  const STATUS = statusProject;
  let status = [];
  let localites = [];
  let packages = [];
  let projectTypes = [];

  useEffect(() => {
    subject$.subscribe(newState => setShow(newState))

  }, [])

  statutes.map((statut) => status.push({ value: statut.code, label: statut.libelle }));

  types.map((type) => projectTypes.push({ value: type.libelle, label: type.libelle }));

  lieux.map((lieu) =>
    localites.push({
      value: lieu.localite,
      label: lieu.localite,
      disabled:
        arrayFilter["code_statut"].length > 0
          ? !checkIfresultContain("lieu_localite", lieu.localite)
          : false,
    })
  );

  investmentPack.forEach((element) => packages.push(parseInt(element)));

  useQuery(GETINTERESSE_BY_PROJET_AND_USER, {
    variables: {
      projet_id: parseInt(props.projetId),
      user_id: parseInt(user?.id),
    },
    onCompleted: (data) => {
      if (data.interesseByProjetAndUser) {
        setInterestedId(data.interesseByProjetAndUser.id);
        setshowBtnInteresse(true);
        interested$.next(true)
      }
    },
    fetchPolicy: "no-cache",
  });

  const [addInteress, { loading }] = useMutation(CREATE_INTERESSER);

  const [deleteIntrested, { loading: loadingDeletIntrest }] = useMutation(
    DELETE_INTERESSE,
    {
      variables: { id: parseInt(interestedId) },
      onCompleted: (data) => {
        setshowBtnInteresse(false);
        setShowUnInterest(true);
        interested$.next(false)
      },
      fetchPolicy: "no-cache",
    }
  );


  const handleShowModalInteresse = () => {

    if (user && !user.email_verified_at) {
      const current = new Date();
      const nextYear = new Date();
      nextYear.setFullYear(current.getFullYear() + 1);
      cookies.set("fromWebsite", true, {
          domain: APP_ENV.domain,
          expires: nextYear,
      });

       window.location.href = APP_ENV.baseURL;

  } else if (user) {
      setShow(true)
    } else {
      navigate("/login", { state: { pathname: pathname } });
    }
  };

  const handleAddInteresse = (currentValue) => {

    addInteress({
      variables: {
        projet_id: parseInt(props.projetId),
        user_id: parseInt(user?.id),
        montant: currentValue
      },
      onCompleted: (data) => {
        setshowBtnInteresse(true);
        setShowInterest(true);
        setInterestedId(data.createInteresse.id);
        setShow(false)
        interested$.next(true)
      },
      fetchPolicy: "no-cache",
    });
  };

  const handleRemoveInteresse = () => {
    if (user) {
      deleteIntrested();
    } else {
      navigate("/login");
    }
  };


  const whatsAppShareButton = () => {
    window.open(
      `https://api.whatsapp.com/send/?phone=&text=${window.location.href}`,
      "mozillaWindow",
      "popup"
    );
  };

  function checkIfresultContain(key, value) {
    let result = projects;

    if (arrayFilter["code_statut"].length > 0) {
      result = result.filter((projet) =>
        arrayFilter["code_statut"].includes(projet["code_statut"])
      );
    }

    if (arrayFilter["libelle_type"].length > 0) {
      result = result.filter((projet) =>
        arrayFilter["libelle_type"].includes(projet["libelle_type"])
      );
    }

    var found = false;
    for (var i = 0; i < result.length; i++) {
      if (result[i][key] === value) {
        found = true;
        break;
      }
    }

    return found;
  }

  return (
    <>
      <Toast
        bg="success"
        onClose={() => setShowInterest(false)}
        show={showInterest}
        delay={5000}
        autohide
        className="custom-toast add-pack text-white rounded-0"
      >
        <Toast.Body>{t("Your interest has been registered")}</Toast.Body>
      </Toast>
      <Toast
        bg="danger"
        onClose={() => setShowUnInterest(false)}
        show={showUnInterest}
        delay={5000}
        autohide
        className="custom-toast add-pack text-white rounded-0"
      >
        <Toast.Body>{t("Your decision has been saved")}</Toast.Body>
      </Toast>

      <div className="products__filter mb-30">
        <div className="products__filter__group">
          <div className="products__filter__header">
            <h5 className="text-center">{t("Project filter")}</h5>
          </div>

          <div className="products__filter__body">
            <Form.Group className="mb-4">
              <Select
                options={projectTypes}
                isMulti
                isSearchable={true}
                hideSelectedOptions={true}
                placeholder={t("Project type")}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={props.handleChangeTypes}
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Select
                options={status}
                isMulti
                isSearchable={true}
                hideSelectedOptions={true}
                placeholder={t("Investment status")}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={props.handleChangeStatus}
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Select
                options={localites}
                isMulti
                isSearchable={true}
                placeholder={t("Locality")}
                className="basic-multi-select"
                classNamePrefix="select"
                isOptionDisabled={(option) => option.disabled}
                onChange={props.handleChangeLocations}
              />
            </Form.Group>
            <Form.Group className="mb-4 d-grid">
              <h6 className="property range py-3">{t("Minimum investment")}</h6>
              <div className="form-group">
                <MultiRangeSlider
                  min={minInvestMin}
                  max={maxInvestMin}
                  step={investMinStep}
                  showCurrncy={true}
                  onChange={({ min, max }) =>
                    props.handleRange("invest_min", min, max)
                  }
                />
              </div>
            </Form.Group>
            <Form.Group className="mb-5 d-grid py-3">
              <h6 className="property range py-3">
                {t("Return on Investment")}
              </h6>
              <div className="form-group">
                <MultiRangeSlider
                  min={minRoi}
                  max={maxRoi}
                  step={roiStep}
                  onChange={({ min, max }) =>
                    props.handleRange("roi", min, max)
                  }
                />
              </div>
            </Form.Group>

            <Form.Group className="mb-4 d-grid">
              <Button
                variant="primary"
                className="btn-search"
                onClick={(e) => props.handleClick(e)}
              >
                {t("Search")}
              </Button>
            </Form.Group>
          </div>
        </div>
      </div>

      <div className="products__filter sticky-top">
        <div className="products__filter__group">
          <div className="products__filter__header">
            <h5 className="text-center text-capitalize">
              {t("What do you want to do")}
            </h5>
          </div>

          <div className="products__filter__body">
            <Form.Group className="mb-4">
              {STATUS === OPENFORINVESTMENT && (
                <div>
                  <Link
                    className="nav-link btn-invest"
                    to={`/client/investment/${project.code}`}
                    state={{project: project
                    }}
                  >
                    {t("I want to invest")}
                  </Link>

                </div>
              )}
              {(STATUS === VOTING || STATUS === VERIFICATIONINPROGRESS) && (
                <>
                  {!showBtnInteresse ? (
                    <>
                      <ButtonInterested
                        loading={loading}
                        handleShowModalInteresse={handleShowModalInteresse}
                      />
                      <ModalInvest
                        show={show}
                        setShow={setShow}
                        min={invest_min}
                        max={projectCout}
                        sautInvestissement={project.saut_investissement}
                        handleAddInteresse={handleAddInteresse}
                        loading={loading}
                      />
                    </>
                  ) : (
                    <ButtonNotintrested
                      loading={loadingDeletIntrest}
                      handleRemoveInteresse={handleRemoveInteresse}
                    />
                  )}
                </>
              )}
            </Form.Group>

            <Form.Group className="mb-4">
              <a
                href="https://wa.me/221770952155"
                target="_blank"
                rel="noreferrer"
                className="nav-link whatsApp "
              >
                <BsWhatsapp /> {t("Contact us by WhatsApp")}
              </a>
            </Form.Group>
            <Form.Group className="d-grid contact">
              <Dropdown>
                <Dropdown.Toggle variant="link" id="dropdown-share">
                  <FaShare /> {t("Share this project")}
                </Dropdown.Toggle>
                <Dropdown.Menu className="rounded-0">
                  <Dropdown.Item className="d-flex justify-content-center align-items-center">
                    <SimpleShareButtons
                      whitelist={["Facebook", "Twitter", "LinkedIn"]}
                    />

                    <ImWhatsapp
                      className="ml-2 mt-2"
                      onClick={whatsAppShareButton}
                      size={25}
                      color="#25d366"
                    />
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Form.Group>
          </div>
        </div>
      </div>
    </>
  );
}
