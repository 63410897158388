import { ImFacebook2, ImLinkedin2, ImWhatsapp } from "react-icons/im";
// import { FaWhatsapp } from "react-icons/fa";
import './Layout.css'

const Banners = () => {

    return (
        <div className="banners topbar d-none d-sm-block">
            <div className="container ">
                <div className="row">
                    <div className="col-sm-12 col-md-5">
                        <div className="topbar-left">
                            <ul className="d-inline-flex list-unstyled mb-0 mt-3 text-white ">
                                {/* <li> 
                                    <b><i className="fa fa-phone-square"></i></b>
                                    <span className="px-2">+221 77 577 50 18</span> 
                                </li> */}
                                <li className="mx-4"> 
                                    <b><i className="fa fa-envelope"></i></b>
                                    <span className="px-2"> contact@reisemarket.com </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-7">
                        <div className="list-unstyled topbar-right">
                            <ul className="topbar-sosmed">
                                <li>
                                    <a href="https://www.facebook.com/reisesenegal/" target="_blank" rel="noreferrer">
                                        <ImFacebook2 className="fw-bold" />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://wa.me/221770952155" target="_blank" rel="noreferrer"> 
                                        <ImWhatsapp className="fw-bold" />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/company/reisesenegal/" target="_blank" rel="noreferrer">
                                        <ImLinkedin2 className="fw-bold" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
   
    )
}
export default Banners;