import { Carousel } from 'react-bootstrap';

import immo from '../../images/immo.png';
import immo2 from '../../images/immo2.png';
import GoogleMaps from '../GoogleMaps';
// import SimilarProjects from './SimilarLots';

const style = {
  width: '100%',
  height: '400px',
};
export default function LotsDescription() {

  return (
    <>
      <div className="single__detail-desc">
        <h6 className="text-capitalize detail-heading">description</h6>
        <div>
          <p>
            Notre processus de sélection est strict, basé sur des critères
            quantitatifs et qualitatifs comme la localisation, l'attractivité de
            l’environnement, le retour sur investissement potentiel,
            l’authenticité des papiers disponibles, l'existence ou pas de litige
            foncier ou de projets étatiques dans la zone, le coût d’acquisition,
            etc. Nous rejetons les projets qui représentent un risque ou ne
            possèdent pas un potentiel intéressant. Notre processus de sélection
            est strict, basé sur des critères quantitatifs et qualitatifs comme
            la localisation, l'attractivité de l’environnement, le retour sur
            investissement potentiel, l’authenticité des papiers disponibles,
            l'existence ou pas de litige foncier ou de projets étatiques dans la
            zone, le coût d’acquisition, etc. Nous rejetons les projets qui
            représentent un risque ou ne possèdent pas un potentiel intéressant.
          </p>
        </div>
      </div>

      <div className="single__detail-features">
        <h6 className="text-capitalize detail-heading">Medias</h6>
        <div className="single__detail-features-video">
          <Carousel fade indicators={false}>
            {/* {projet.photos?.map((img) => ( */}
            <Carousel.Item>
              <img
                className="d-block w-100 carousel-image"
                src={immo}
                alt="First slide"
              />
            </Carousel.Item>
            {/* ))} */}
          </Carousel>
        </div>
      </div>

      <div className="single__detail-features">
        <h6 className="text-capitalize detail-heading">video</h6>
        <div className="single__detail-features-video">
          <figure className=" mb-0">
            <div className="home__video-area text-center">
              <iframe
                className="img-fluid video-image"
                src={`https://www.youtube.com/embed/aYQHMsLa6IM`}
                style={{ width: '100%', height: '445px', border: 0 }}
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
                title="video"
              />
              {/* } */}
            </div>
          </figure>
        </div>
      </div>

      <div className="single__detail-features">
        <h5 className="text-capitalize detail-heading">plans</h5>
        <Carousel fade indicators={false}>
          {/* {projet.plans?.map((img) => ( */}
          <Carousel.Item>
            <img
              className="d-block w-100 carousel-image"
              src={immo2}
              alt="Plan"
            />
          </Carousel.Item>
          {/* ))} */}
        </Carousel>
      </div>

      <div className="single__detail-features">
        <h5 className="text-capitalize detail-heading">Localisation</h5>
        <GoogleMaps markers={[]} containerStyle={style} />
      </div>

      <div className="single__detail-features">
        <h5 className="text-capitalize detail-heading">Points d'intérêt</h5>
        <div className="row">
          <div className="col-md-6">
            <div className="single__detail-features-nearby">
              <h6 className="text-capitalize font-heading text-gray">
                {' '}
                A proximité :
              </h6>
              <ul className="list-unstyled nearby">
                {/* {aproximite?.map((item, idx) => ( */}
                <li>
                  <span>1 Points d'intérêt</span>
                  <p>225 km</p>
                </li>
                <li>
                  <span>2 Points d'intérêt</span>
                  <p>225 km</p>
                </li>
                <li>
                  <span>3 Points d'intérêt</span>
                  <p>225 km</p>
                </li>
                <li>
                  <span>4 Points d'intérêt</span>
                  <p>225 km</p>
                </li>
                <li>
                  <span>5 Points d'intérêt</span>
                  <p>225 km</p>
                </li>
                <li>
                  <span>6 Points d'intérêt</span>
                  <p>225 km</p>
                </li>
                {/* ))} */}
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="single__detail-features-nearby">
              <h6 className="text-capitalize font-heading text-gray">
                {' '}
                Accès :
              </h6>
              <p className="text-gray">
                Cras sit amet nibh libero, in gravida nulla. Nulla vel metus
                scelerisque ante sollicitudin. Cras purus odio, vestibulum in
                vulputate at, tempus viverra turpis. Fusce condimentum nunc ac
                nisi vulputate fringilla. Donec lacinia congue felis in
                faucibus. Cras sit amet nibh libero, in gravida nulla. Nulla vel
                metus scelerisque ante sollicitudin. Cras purus odio, vestibulum
                in vulputate at, tempus viverra turpis. Fusce condimentum nunc
                ac nisi vulputate fringilla. Donec lacinia congue felis in
                faucibus.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
