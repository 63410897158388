import { FaMapMarkerAlt } from 'react-icons/fa';
import immo2 from '../../images/immo2.png';

import { ListGroup } from 'react-bootstrap';
import '../cards/cardImg.css';

function CardImg(props) {
  const { detailsLots } = props;
  // const { currency } = useCurrency();
// console.log("currency", currency)
  return (
    <div className="card__image card__box-v1 mb-5">
      <div className="row">
        <div className="col-lg-6">
          <div className="card__image__header h-250">
            <a href="#">
              <img
                src={immo2}
                alt=""
                className="img-fluid w100 h-auto img-transition"
              />
            </a>
          </div>
        </div>
        <div className="col-lg-5 mt-lg-n4 ">
          <div className="card__image__body h-250">
            <ListGroup variant="flush" className="ml-n2 ">
              <ListGroup.Item
                as="li"
                className="d-flex justify-content-between custom-list-group-item mt-lg-n3 projet-details"
              >
                <div className="fw-bold fs-5">{detailsLots?.titre}</div>
              </ListGroup.Item>
              <ListGroup.Item
                as="li"
                className="d-flex justify-content-between custom-list-group-item projet-details"
              >
                <div className="info">PAYABLE EN TRANCHES</div>
              </ListGroup.Item>
              <ListGroup.Item
                as="li"
                className="d-flex justify-content-between custom-list-group-item projet-details"
              >
                <div>Localité</div>
                <div className="text-capitalize">
                  <FaMapMarkerAlt size={20} /> Banbilor, Thies
                </div>
              </ListGroup.Item>
              <ListGroup.Item
                as="li"
                className="d-flex justify-content-between custom-list-group-item projet-details mt-3"
              >
                <div>Type de papier </div>
                <span className="fw-500">Délibération</span>
              </ListGroup.Item>
              <ListGroup.Item
                as="li"
                className="d-flex justify-content-between custom-list-group-item projet-details mt-3"
              >
                <div>Parcelle</div>
                <div className="fw-500">
                  {detailsLots?.terrains?.map((item, index) => (
                    <span className="pr-4" key={index}>
                      {item.superficie}m <sup>2</sup>
                      <span className="parcelle-num">({item.num_plle})</span>
                    </span>
                  ))}
                </div>
              </ListGroup.Item>
            </ListGroup>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardImg;
