import React, { useCallback, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import "./multiRangeSlider.css";
import { CurrencyConverter } from "../../utils/utils";

const MultiRangeSlider = ({ min, max, onChange, step, showCurrncy=false}) => {
  const [minVal, setMinVal] = useState(min);
  const [maxVal, setMaxVal] = useState(max);
  const minValRef = useRef(min);
  const maxValRef = useRef(max);
  const range = useRef(null);

  // Convert to percentage
  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  // Set width of the range to decrease from the left side
  useEffect(() => {
    const minPercent = getPercent(minVal);
    const maxPercent = getPercent(maxValRef.current);

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [minVal, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    const minPercent = getPercent(minValRef.current);
    const maxPercent = getPercent(maxVal);

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [maxVal, getPercent]);

  // const callback = useCallback(() => {
  //   onChange({ min: minVal, max: maxVal });
  // },[minVal, maxVal, onChange])

  //Get min and max values when their state changes
  useEffect(() => {
    handleChange()
  },[minVal,maxVal])


  const handleMin = (event) => {
      const value = Math.min(Number(event.target.value), maxVal - 1);
      setMinVal(value);
      minValRef.current = value;
  }

  const handleMax = (event) => {
      const value = Math.max(Number(event.target.value), minVal + 1);
      setMaxVal(value);
      maxValRef.current = value;
  }
 
  const handleChange = () => {
     onChange({ min: minVal, max: maxVal })
  }

  return (
    <div>
      <input
        type="range"
        min={min}
        max={max}
        value={minVal}
        step={step}
        onChange={handleMin}
        className="thumb thumb--left"
        style={{ zIndex: minVal > max - 100 && "5" }}
      />
      <input
        type="range"
        min={min}
        max={max}
        value={maxVal}
        step={step}
        onChange={handleMax}
        className="thumb thumb--right"
      />

      <div className="slider">
        <div className="slider__track" />
        <div ref={range} className="slider__range" />
        <div className="slider__left-value">min: {showCurrncy ? CurrencyConverter(minVal) : minVal}</div>
        <div className="slider__right-value">max: {showCurrncy ? CurrencyConverter(maxVal): maxVal }</div>
      </div>
    </div>
  );
};

MultiRangeSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired
};

export default MultiRangeSlider;
